import store from '@/store';
import payStore from '../store/index';
let isRegistered = false;
export function registerStore() {
  if (!isRegistered) {
    store.registerModule('pay', payStore, {
      preserveState: !!store.state['pay']
    });
    isRegistered = true;
  }
}

export default function init() {
  //do initial work
  registerStore();
}
