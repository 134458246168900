/**
 * @typedef {import('./state').dashbaordState} dashbaordState
 */
import state from './state';
import Vue from 'vue';
/** @type {import('axios').AxiosInstance} */
const $serverRequest = Vue.$serverRequest
export default {
  namespaced: true,
  state,
  getters:{
    getSelectedBusiness(state) {
      return state.selectedBusiness
    },
    getManagers(state) {
      return state.managers
    },
    getManager(state) {
      return state.manager
    }
  },
  mutations:{
    setManagers(state,payload){
      state.managers = payload
    },
    setManager(state,payload){
      state.manager = payload
    },
    setSelectedBusiness(state, payload) {
      state.selectedBusiness = payload
    }
  },
  actions: {
    fetchManagers({commit}){
      return $serverRequest.get('/v1/pilot/list')
        .then(res => {
          commit('setManagers',res.data)
          return res.data
        })
        .catch(e => e)
    }
  }
};
