import vue from 'vue';
import Vuex from 'vuex';
import { createStore } from 'vuex-extensions';
import VuexPersistence from 'vuex-persist';
const vuexLocal = new VuexPersistence({
  // storage: window.sessionStorage,
  storage: process.env.VUE_APP_ENV === 'prodtest' ? window.localStorage : window.sessionStorage,
  modules: [
    'user',
    'kyc',
    'kyb',
    'account',
    'card',
    'dashboard',
    'transaction',
    'contact',
    'accounting',
    'checkDeposit',
  ]
});

vue.use(Vuex);
// vue.config.devtools = true;

const isProduction = process.env.VUE_APP_ENV !== 'development';

const store = createStore(Vuex.Store, {
  // plugins: isProduction ? [] : [vuexLocal.plugin],
  plugins: [vuexLocal.plugin],
  state: {
    activeEnv: process.env.VUE_APP_ENV,
    isUserClickBack: false,
    logoutPopupStatus: false,
    isMobileLayout: false,
    isSidebarVisible: false
  },
  getters: {
    getActiveEnv(state) {
      return state.activeEnv;
    },
    getIsUserClickBack(state) {
      return state.isUserClickBack;
    },
    getLogoutPopupStatus(state) {
      return state.logoutPopupStatus;
    },
    getIsMobileLayout(state) {
      return state.isMobileLayout;
    },
    getIsSidebarVisible(state) {
      return state.isSidebarVisible;
    }
  },
  mutations: {
    setActiveEnv(state, env) {
      state.activeEnv = env;
    },
    userClickedBack(state, value) {
      state.isUserClickBack = value;
    },
    updateLogoutPopupStatus(state, value) {
      state.logoutPopupStatus = value;
    },
    setIsMobileLayout(state, value) {
      state.isMobileLayout = value;
    },
    setIsSidebarVisible(state, value) {
      state.isSidebarVisible = value;
    }
  }
});

export default store;
