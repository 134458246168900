/**
 * @typedef {import('./state').fundState} fundState
 */
import state from './state';
/** @type {import('axios').AxiosInstance} */

export default {
  namespaced: true,
  state,
  getters: {
    get_shifl_instant_pay_person(state){
      return state.shifl_instant_pay_person
    },
    shifl_instant_pay_person_accounts(state){
      return state.shifl_instant_pay_person_accounts
    },
    shifl_instant_pay_selected_account(state){
      return state.shifl_instant_pay_selected_account
    }
  },
  mutations: {
    setMoveFundResponseDetails(state, payload) {
      state.moveFundResponseDetails = payload;
    },
    set_shifl_instant_pay_person(state, payload){
      state.shifl_instant_pay_person = payload
    },
    set_shifl_instant_pay_selected_account(state, payload){
      state.shifl_instant_pay_selected_account = payload
    }
  },
  actions: {}
};
