import Vue from 'vue';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
locale.use(lang);

import {
  Form,
  FormItem,
  Button,
  Select,
  Option,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  Row,
  Col,
  Checkbox,
  Radio,
  RadioGroup,
  Upload,
  Notification,
  Message,
  Loading,
  MessageBox,
  Table,
  DatePicker,
  TableColumn,
  Dialog,
  Switch,
  Card,
  Aside,
  Menu,
  Submenu,
  MenuItem,
  Container,
  Tag,
  Header,
  Main,
  Link,
  Drawer,
  InfiniteScroll,
  Progress,
  InputNumber,
  Popover,
  Carousel,
  CarouselItem,
  TimeSelect,
  Collapse,
  CollapseItem,
  Skeleton,
  SkeletonItem
} from 'element-ui';

// const createdMethod = Input.created;
// const beforeDestroy = Input.beforeDestroy;
// const exp = /[%\*\+,\-\/;<=>\^\|\'"]/; // eslint-disable-line
//
// const keyPressHandler = function (e) {
//   const key = e.key;
//   if (exp.test(key)) {
//     e.preventDefault();
//   }
// };
//
// const pasteHandler = function (e) {
//   const event = e;
//   setTimeout(() => {
//     event.target.value = event.target.value.replace(
//       new RegExp(exp.source, 'g'),
//       ''
//     );
//   }, 0);
// };
//
// Input.created = function () {
//   createdMethod.call(this);
//   setTimeout(() => {
//     const el = this.getInput();
//     if (el) {
//       el.addEventListener('keypress', keyPressHandler, false);
//       el.addEventListener('paste', pasteHandler, false);
//     }
//   }, 0);
// };
//
// Input.beforeDestroy = function () {
//   if (beforeDestroy) {
//     beforeDestroy.call(this);
//   }
//   const el = this.getInput();
//   el.removeEventListener('keypress', this.keyPressHandler);
//   el.removeEventListener('paste', this.pasteHandler);
// };

Vue.use(Form);
Vue.use(Dialog);
Vue.use(Card);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Select);
Vue.use(Option);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Input);
Vue.use(Row);
Vue.use(Col);
Vue.use(Checkbox);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Upload);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(Switch);
Vue.use(Progress);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(TimeSelect);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Skeleton);
Vue.use(SkeletonItem);
Vue.use(Loading.directive);

Vue.use(Aside);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Container);
Vue.use(Tag);
Vue.use(Header);
Vue.use(Main);
Vue.use(Link);
Vue.use(Drawer);
Vue.use(InfiniteScroll);
Vue.use(InputNumber);
Vue.use(Popover);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
