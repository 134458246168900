<template>
  <Alert v-show="warningZone"
         class="auto-logout-alert"
         title="Are you still there? You will be logged out due to inactivity"
         type="warning"
         :closable="false"
         :description="logoutCountDown" />
</template>

<script>

import loginmixin from '../modules/user/mixins/login'
import {Alert} from 'element-ui'

export default {
  components: {
    Alert
  },
  mixins: [loginmixin],
  name: 'AutoLogout',
  data() {
    return {
      timer: null,
      warning: null,
      warningCounter: null,
      warningZone: false,
      timeLeft: Number.MAX_SAFE_INTEGER,
      windowEvents: ['click', 'mousemove', 'keydown', 'load','scroll','touchstart'],
    }
  },
  created() {
    this.setTimer()
  },
  mounted() {
    this.windowEvents.forEach(event => {
      window.addEventListener(event, this.resetTimer)
    })
  },
  destroyed() {
    clearTimeout(this.timer)
    clearTimeout(this.warning)
    this.clearWarning()
    this.windowEvents.forEach(event => {
      window.removeEventListener(event, this.resetTimer)
    })
  },
  methods: {
    setTimer() {
      const anHour = 60 * 60 * 1000
      this.timer = setTimeout(() => {
        if (!document.cookie.match('remember_client=30days')) this.logoutUser();
      }, anHour)
      this.warning = setTimeout(() => {
        if (document.cookie.match('remember_client=30days')) return;
        this.warningZone = true
        this.timeLeft = 30;
        this.warningCounter = setInterval(() => {
          this.timeLeft -= 1
        },1000)

        //anHour minus 30 sec
      }, anHour - (30 * 1000))
    },
    resetTimer() {
      this.clearWarning()
      clearTimeout(this.timer);
      this.setTimer()
    },
    clearWarning(){
      clearTimeout(this.warning);
      clearInterval(this.warningCounter)
      this.warningZone = false
    }
  },
  computed:{
    logoutCountDown(){
      return `Logging out in ${this.timeLeft}...`
    }
  }
}
</script>

<style lang="scss">
.auto-logout-alert {
  position: absolute !important;
  height: auto !important;
  z-index: 99999;
  display: flex;
  color: black !important;
  text-align: center;
  justify-content: center;
  *{
    font-size: 1rem !important;
  }
}
</style>