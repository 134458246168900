import Send from './index.vue';

const routes = [
  {
    path: '/send',
    component: Send,
    children: [
      {
        path: '',
        name: 'FundDetails',
        component: () => import('./views/SendView.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
    ]
  }
];

export default routes;
