/**
 * @typedef {import('./state').CheckDepositState} CheckDepositState
 */
import state from './state';
import {receiveService} from '@/services/solidfi';

export default {
  namespaced: true,
  state,
  getters: {
    /**
     * get selected contact data
     * @param {CheckDepositState} state
     * @return {CheckDepositState['selectedContact']}
     */
    getSelectedContact(state) {
      return state.selectedContact;
    },
    /**
     * get selected contact data
     * @param {CheckDepositState} state
     * @return {CheckDepositState['successDepositData']}
     */
    getSuccessDepositData(state) {
      return state.successDepositData;
    },
    /**
     * get check deposit list
     * @param {CheckDepositState} state
     * @return {CheckDepositState['checkDepositList']}
     */
    getCheckDepositList(state) {
      return state.checkDepositList;
    },
    /**
     * get check deposit form data
     * @param {CheckDepositState} state
     * @return {CheckDepositState['checkDepositFormData']}
     */
    getCheckDepositFormData(state) {
      return state.checkDepositFormData;
    },
    getCheckDeposit(state){
      return state.checkDeposit
    }
  },
  mutations: {
    setCheckDeposit(state,payload){
      state.checkDeposit = payload
    },
    /**
     * set selected contact data
     * @param {CheckDepositState} state
     * @param {CheckDepositState['selectedContact'] | null} payload
     */
    setSelectedContact(state, payload) {
      state.selectedContact = payload;
    },
    /**
     * set success deposit data
     * @param {CheckDepositState} state
     * @param {*} payload
     */
    setSuccessDepositData(state, payload) {
      state.successDepositData = payload;
    },
    /**
     * set check deposit form data
     * @param {CheckDepositState} state
     * @param {*} payload
     */
    setCheckDepositFormData(state, payload) {
      state.checkDepositFormData = payload;
    },
    /**
     * update check deposit list
     * @param {CheckDepositState} state
     * @param {CheckDepositState['checkDepositList']} payload
     */
    updateCheckDepositList(state, payload) {
      state.checkDepositList = payload;
    }
  },
  actions: {
    /**
     * check deposit
     * @param {*} _
     * @param {*} payload
     * @returns
     */
    createCheckDeposit(_, payload) {
      return new Promise((resolve, reject) => {
        receiveService.check(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * update check deposit files
     * @param {*} _
     * @param {*} payload
     * @returns
     */
    updateCheckDepositFiles(_, payload) {
      return new Promise((resolve, reject) => {
        receiveService.uploadCheckFiles(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * get check deposit status
     * @see {@link https://docs.sbx.solidfi.com/#f6c1d4cb-a898-4c2b-8016-3196b9de1535}
     * @param {*} param0
     * @param {*} payload
     */
    getStatus({ commit }, accountId) {
      return new Promise((resolve, reject) => {
        receiveService.allCheckDeposited(accountId)
          .then((res) => {
            commit('updateCheckDepositList', res.data.data);
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      });
    }
  }
};
