import SolidService from './solid.service'

let personService = class PersonService extends SolidService {

  constructor() {
    super();
  }

  person(params = {}) {
    let url = this.backendSolidBaseUrl + '/person';
    return super.get(url, params);
  }

  update($person_id, params = {}) {
    let url = this.backendSolidBaseUrl + `/person/${$person_id}`;
    return super.patch(url, params);
  }

  submitIDV($person_id){
    const url = this.backendSolidBaseUrl + `/person/${$person_id}/idv`;
    return super.post(url);
  }

  retrieveIDV($person_id){
    const url = this.backendSolidBaseUrl + `/person/${$person_id}/idv`;
    return super.get(url);
  }

  retrieveKYC($person_id){
    const url = this.backendSolidBaseUrl + `/person/${$person_id}/kyc`;
    return super.get(url);
  }

  submitKYC($person_id){
    const url = this.backendSolidBaseUrl + `/person/${$person_id}/kyc`;
    return super.post(url);
  }
};

export default personService
