import SolidService from './solid.service'

let contactService = class ContactService extends SolidService {

  constructor() {
    super();
  }

  contact($id,$params) {
    let url = this.backendSolidBaseUrl + '/contact/' + $id;
    return super.get(url,$params);
  }
  contacts($accountId,$params) {
    let url = this.backendSolidBaseUrl + '/contact/list/' + $accountId;
    return super.get(url,$params);
  }
  createContact($payload) {
    let url = this.backendSolidBaseUrl + '/contact';
    return super.post(url,$payload);
  }
  updateContact($id,$payload) {
    let url = this.backendSolidBaseUrl + '/contact/' + $id;
    return super.patch(url,$payload);
  }
  deleteContact($id) {
    let url = this.backendSolidBaseUrl + '/contact/' + $id;
    return super.delete(url);
  }
};

export default contactService
