<template>
	<v-app>
    <v-main>
			<v-container>
				<router-view />
			</v-container>
    </v-main>
	</v-app>
</template>

<script>
export default {

}
</script>

<style>

</style>