import axios from 'axios';
import { getEnv } from '@/utils/env';
import store from '@/store'
import {Axios} from '@/plugins/serverRequest';

let baseService = class BaseService {
    baseURL = ''
    headers
    $http

    constructor() {

      // this.baseURL = getEnv('VUE_APP_SHIFL_BASE_URL')

      this.$http = Axios

      this.$http.interceptors.request.use(config => {
        return config
      })

      this.$http.interceptors.response.use(response => {
        return response
      })        
    }

    getJson(uri = '', data = {}) {
      if (Object.keys(data).length > 0) {
        uri = `${uri}?${this.getQueryString(data)}`
      }

      return this.$http.get(uri)
    }

    getHeaders(additionalHeaders = {}) {
      if(store.getters['user/getPilot']) additionalHeaders['scapital-piloting'] = store.getters['user/getPilot'].id;
      return additionalHeaders
    }

    prepareUrl(url, params) {
      for (let index in params) {
        let identifier = ':' + index;
        url = url.replace(identifier, params[index]);
      }
      return url;
    }

    getQueryString(params) {
      return (
        Object
          .keys(params)
          .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
          .join('&')
      )
    }

    post(uri = '', data = {}, additionalHeaders = {}, responseType = 'json') {
      return this.$http.post(uri, data, {
        headers: this.getHeaders(additionalHeaders),
        responseType: responseType
      })
    }

    put(uri = '', data, additionalHeaders = {}) {
      return this.$http.put(uri, data, {
        headers: this.getHeaders(additionalHeaders),
      })
    }

    patch(uri = '', data, additionalHeaders = {}) {
      return this.$http.patch(uri, data, {
        headers: this.getHeaders(additionalHeaders),
      })
    }

    delete(uri = '', data = {}, additionalHeaders = {}) {
      return this.$http.delete(uri, {
        headers: this.getHeaders(additionalHeaders),
        data: data
      });
    }

    remove(uri = '', data = {}, additionalHeaders = {}) {
      return this.$http(uri, {
        method: 'DELETE',
        headers: this.getHeaders(additionalHeaders),
        data: data
      })
    }

    get(uri = '', data = {}, additionalHeaders = {}, responseType = 'json') {
      if (Object.keys(data).length > 0) {
        uri = `${uri}?${this.getQueryString(data)}`
      }

      return this.$http.get(uri, {
        headers: this.getHeaders(additionalHeaders),
        responseType: responseType
      })
    }
};

export default baseService
