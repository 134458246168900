import SolidService from './solid.service'

let programService = class ProgramService extends SolidService {

  constructor() {
    super();
  }

  program($id) {
    let url = this.backendSolidBaseUrl + '/program/' + $id;
    return super.get(url);
  }

  persons(params = {}) {
    let url = this.backendSolidBaseUrl + '/program/persons';
    return super.post(url,params);
  }
};

export default programService
