import SolidService from '@/services/solidfi/solid.service';

export default class CardService extends SolidService {
  constructor() {
    super();
  }

  get($id) {
    const url = this.backendSolidBaseUrl + `/card/${$id}`
    return super.get(url);
  }

  create($payload) {
    const url = this.backendSolidBaseUrl + '/card'
    return super.post(url, $payload);
  }

  delete($id) {
    const url = this.backendSolidBaseUrl + `/card/${$id}`
    return super.delete(url);
  }

  update($id, $payload) {
    const url = this.backendSolidBaseUrl + `/card/${$id}`
    return super.patch(url, $payload);
  }

  undRedact($id) {
    const url = this.backendSolidBaseUrl + `/card/${$id}/unredact`
    return super.get(url);
  }

  listAll($account_id, limit = 25, offset = 0) {
    const url = this.backendSolidBaseUrl + `/card/list/${$account_id}`
    return super.get(url, {limit, offset});
  }

  listAllATM($id, lat, long, limit = 25, offset = 0) {
    const url = this.backendSolidBaseUrl + `/card/atm/${$id}`
    return super.get(url, {limit, offset, lat, long});
  }

  showToken($id) {
    const url = this.backendSolidBaseUrl + `/card/${$id}/showtoken`
    return super.post(url);
  }

  activate($id, $payload) {
    const url = this.backendSolidBaseUrl + `/card/${$id}/activate`
    return super.post(url, $payload);
  }

  pinToken($id) {
    const url = this.backendSolidBaseUrl + `/card/${$id}/pintoken`
    return super.post(url);
  }
}