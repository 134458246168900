/**
 * @typedef {import('./state').cardState}
 */
import state from './state';
import {cardService} from '@/services/solidfi';

/** @type {import('axios').AxiosInstance} */
export default {
  namespaced: true,
  state,
  mutations: {
    /**
         * update create card data
         * @param {state} state
         * @param {state['createCardData']} payload
         */
    updateCreateCardData(state, payload) {
      state.createCardData = payload;
    },
    /**
         * update selected card
         * @param {state} state
         * @param {state['createCardData']} payload
         */
    updateSelectedCard(state, payload) {
      state.selectedCard = payload;
    },
    /**
         * update selected card
         * @param {state} state
         * @param {state['cards']} payload
         */
    updateCards(state, payload) {
      state.cards = payload;
    },
    /**
         * update replace card data
         * @param {state} state
         * @param {state['replaceCardData']} payload
         */
    updateReplaceCardDate(state, payload) {
      state.replaceCardData = payload;
    }
  },
  getters: {
    /**
         * get create card data
         * @param {state} state
         * @returns {state['createCardData']}
         */
    getCreateCardData(state) {
      return state.createCardData;
    },
    /**
         * get selected card data
         * @param {state} state
         * @returns {state['selectedCard']}
         */
    getSelectedCard(state) {
      return state.selectedCard;
    },
    /**
         * get cards
         * @param {state} state
         * @returns {state['cards']}
         */
    getCards(state) {
      return state.cards;
    },
    /**
         * get replace card data
         * @param {state} state
         * @returns {state['replaceCardData']}
         */
    getReplaceCardData(state) {
      return state.replaceCardData;
    }
  },
  actions: {
    /**
         * create card
         * @param {Object} param0
         * @param {Function} param0.commit
         * @returns {Promise<import('./state').cardState['createCardData']>}
         */
    createCard({commit}, payload) {
      return new Promise((resolve, reject) => {
        cardService.create(payload)
          .then(({data}) => {
            console.log(data);
            commit('updateSelectedCard', data);
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
         * list all card
         * @param {*} param0
         * @param {string} accountId
         */
    listAllCardByAccount({commit, state}, {accountId, limit = 25, offset = 0}) {
      return new Promise((resolve, reject) => {
        cardService.listAll(accountId, limit, offset)
          .then(({data}) => {
            let d = {};
            if (offset === 0) {
              d = data;
            } else {
              const cards = {...state.cards};
              d = {
                data: [...cards.data, ...data.data],
                total: data.total
              }
            }
            commit('updateCards', d);
            resolve(d);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
         * Activate card
         * @param {*} param0
         * @param {string} param1.cardId
         * @param {*} param1.payload
         */
    activate(_, {cardId, payload}) {
      return new Promise((resolve, reject) => {
        cardService.activate(cardId, payload)
          .then(({data}) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
         * fetch uniqe token for vgs show
         * @param {*} _
         * @param {*} cardId
         * @returns
         */
    showToken(_, cardId) {
      return new Promise((resolve, reject) => {
        cardService.showToken(cardId)
          .then(({data}) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
         * @deprecated
         * @param {*} _
         * @param {string} cardId
         */
    cardUnredact(_, cardId) {
      return new Promise((resolve, reject) => {
        cardService.undRedact(cardId).then(({data}) => {
          resolve(data);
        })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
         * Delete card
         * @param {*} _
         * @param {string} cardId
         */
    cardDelete(_, cardId) {
      return new Promise((resolve, reject) => {
        cardService.delete(cardId)
          .then(({data}) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
         * Replace card
         * @param {*} _
         * @param {string} cardId
         */
    replaceCard(_, cardId) {
      //TODO: Need to change
      return new Promise((resolve, reject) => {
        cardService.delete(cardId)
          .then(({data}) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
         * update card
         * @param {Object} param0
         * @param {Function} param0.commit
         * @param {Object} param1
         * @param {string} param1.cardId
         * @param {Object} param1
         */
    updateCard({commit}, {cardId, payload}) {
      return new Promise((resolve, reject) => {
        cardService.update(cardId, payload)
          .then(({data}) => {
            commit('updateSelectedCard', data);
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
         * get atm pin vgs token
         * @param {*} _
         * @param {*} cardId
         * @returns
         */
    pinToken(_, cardId) {
      return new Promise((resolve, reject) => {
        cardService.pinToken(cardId)
          .then(({data}) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
         * find near atm
         */
    findNearATM(_, {cardId, lat, long, limit = 25, page = 1}) {
      return new Promise((resolve, reject) => {
        const offset = limit * (page - 1);
        cardService.listAllATM(cardId, lat, long, limit, offset)
          .then(({data}) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });

    }
  }
};
