import axios from 'axios';
import { getEnv } from '@/utils/env';

const baseURL = getEnv('VUE_APP_SHIFL_BASE_URL')

const setQuickbooksAccessToken =  async ( {rootState}, {code, realmId} ) => {
  try {
    const accessToken = rootState.user.authToken;
    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
    return await axios.post(baseURL+'/v1/quickbooks/exchange-token', {
      code,
      realmId
    }, options);
  } catch(error) {
    throw error;
  }
  
}

export default{
  setQuickbooksAccessToken,
}