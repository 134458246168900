<template>
  <div v-if="(companyInformation && !isCreatingCompany && !showConnectQBO) || inSettingsPage" class="widgetContainer widgetContainer--new full-width dashboard-router-view accounting-module">
    <router-view />
  </div>
  <div class="widgetContainer full-width" v-else>
    <div class="widgetContainer__header-fixed">
    </div>
    <v-app class="vue-app">
      <v-main>
        <v-container fluid>
          <v-card class="text-center pa-12" flat :loading="isCheckingQBO">
            <v-card-text>
              <h2 class="header-2">
                {{ $t('accounting_connect_message') }}
              </h2>
              <v-btn
                color="primary"
                @click="fetchQBO"
                :loading="isCheckingQBO"
              >
                <v-icon class="mr-2">mdi-login-variant</v-icon>
                {{ $t('sign_in_qbo') }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>

import { mapActions, mapState, mapMutations } from 'vuex';
import { apiErrorMessage } from '@/utils/general';

import init from './setup';

init();

export default {
  name: 'Accounting',
  data: () => {
    return {
      isCreatingCompany: false,
      errorMessage: '',
      isFetchingSignInURL: false,
      isCheckingQBO: false,
    };
  },
  created() {

    if(!this.companyInformation) {
      this.checkAccounting();
    }

    if(this.companyInformation && this.companyInformation?.accounting_company?.qbo_realm_id) {
      this.showConnectQBO = true;
      // Automatically fetch the QBO if the last navigation is from quickbooks module
      if(this.$route.query.from === 'redirect') {
        this.fetchQBO();
      }
    }

  },
  computed: {
    ...mapState('accounting', [
      'companyInformation',
      'isCheckingAccounting',
      'QBOInformation',
    ]),
    showConnectQBO: {
      get() {
        return this.companyInformation && this.companyInformation?.accounting_company?.qbo_realm_id && !this.QBOInformation;
      },
      set(value) {
        return value;
      }
    },
    inSettingsPage() {
      return this.$route.path === '/accounting/settings';
    }
  },
  methods: {
    ...mapActions('accounting', [
      'getAccounting',
      'createAccountingCompany',
      'getQuickbooksLoginUrl',
      'setLastUrlPath',
      'getQBOConnection',
    ]),
    ...mapMutations('accounting', 
      ['updateAccountingCompany', 'updateQBO']
    ),

    // Check the accounting if there is company associated with the current user.
    // Create a company if the @success is false
    async checkAccounting() {
      try {
        const { data } = await this.getAccounting();
        if(!data) {
          this.isCreatingCompany = true;
          await this.createAccountingCompany();
          this.isCreatingCompany = false;
          this.isCheckingQBO = false;
        } else {
          // Save the returned company if exists
          this.updateAccountingCompany(data);

          // Fetch the QBO if this user has QBO
          if(this.companyInformation.accounting_company.qbo_realm_id) {
            this.isCheckingQBO = true;
            await this.fetchQBO();
          }
          
        }
      } catch(error) {
        apiErrorMessage(error);
      }
    },

    async fetchQBO() {
      this.isCheckingQBO = true;
      try {
        const { data, message } = await this.getQBOConnection();
        if(!data) {
          apiErrorMessage(message);
        } else {
          this.updateQBO(data);
          this.isCheckingQBO = false;
        }
      } catch(error) {
        // Suppress the error of QBO
        // Since we know that this function only runs when the user has QBO,
        // We will get now the sign-in url here.
        this.onSignIn();
      }
    },

    async onSignIn() {
      if(this.isFetchingSignInURL) {
        return;
      }

      this.isFetchingSignInURL = true;
      try {
        this.setLastUrlPath(this.$route.path);
        const url = await this.getQuickbooksLoginUrl();

        if(url) {
          window.open(url, "_self");
        }

        // Already connected
        if(url === null) {
          this.fetchQBO();
        }
      } catch(error) {
        apiErrorMessage(error);
        this.isFetchingSignInURL = false;
      }
    },

  }
};
</script>
<style scoped lang="scss">
  .accounting-module {
    padding: 72px 0px 0px 24px;

    .widgetContainer__body {
      padding: 40px 32px 0px 0px;
    }

    @media screen and(max-width: 992px) {
      // padding-right: 32px;
      padding: 0;
      .widgetContainer__body {
        padding: 0;
      }
    }

    @media screen and(max-width: 768px) {
      padding: 0;
      .widgetContainer__body {
        padding: 0px 0px 0px 0px;
      }
    }
    
  }

  .vue-app {
    background: none;
  }

  .v-card__progress {
    height: 4px !important;
  }
</style>
