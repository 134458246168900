import SolidService from '@/services/solidfi/solid.service';

export default class TransactionService extends SolidService{
  constructor() {
    super();
  }

  get($account_id,$transaction_id,$params){
    const uri = this.backendSolidBaseUrl + `/transaction/list/${$account_id}/transaction/${$transaction_id}`;
    const responseType = $params.export === 'pdf' ? 'blob' : 'json';
    return super.post(uri,$params, {},responseType)
  }
    
  all($account_id,$params){
    const uri = this.backendSolidBaseUrl + `/transaction/list/${$account_id}`
    return super.get(uri,$params);
  }
}