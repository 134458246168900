import store from '@/store';
import quickbooksStore from '../store/index';

export function registerStore() {
  if (process.env.VUE_APP_ENV !== 'development') {
    store.registerModule('quickbooks', quickbooksStore, {
      preserveState: true,
    });
  } else {
    if (!quickbooksStore.registered) {
      store.registerModule('quickbooks', quickbooksStore, {
        preserveState: !!store.state['quickbooks']
      });
      quickbooksStore.registered = true;
    }
  }
}

export default function init() {
  //do initial work
  registerStore();
}
