/**
 * @typedef {Object} fundState
 * @property {plaidTokenDetails} plaidTokenDetails
 * @property {Object} pullFundDetails
 * @property {Object} pullFundResponseDetails
 * @property {Object} moveFundDetails
 * @property {Object} moveFundResponseDetails
 */
export default {
  shifl_instant_pay_person: null,
  shifl_instant_pay_person_accounts: [],
  shifl_instant_pay_selected_account: null,
};
