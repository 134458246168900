import SolidService from '@/services/solidfi/solid.service';

export default class MemberService extends SolidService {
  constructor() {
    super();
  }

  create($params) {
    const uri = this.backendSolidBaseUrl + '/member'
    return this.post(uri, $params);
  }

  update($id, $params) {
    const uri = this.backendSolidBaseUrl + `/member/${$id}`
    return this.patch(uri,$params);
  }

  remove($id) {
    const uri = this.backendSolidBaseUrl + `/member/${$id}`
    return this.delete(uri);
  }


  retrieve($id) {
    const uri = this.backendSolidBaseUrl + `/member/${$id}`
    return this.get(uri);
  }

  all($business_id) {
    const uri = this.backendSolidBaseUrl + `/member/list/${$business_id}`
    return this.get(uri);
  }

  retrieveKYC($id) {
    const uri = this.backendSolidBaseUrl + `/member/${$id}/kyc`
    return this.get(uri);
  }

  submitKYC($id) {
    const uri = this.backendSolidBaseUrl + `/member/${$id}/kyc`
    return this.post(uri);
  }
}