<template>
  <div>
    <div class="sidebar-backdrop"
         @click="hideSidebar"
         v-if="getIsSidebarVisible" />
    <el-aside
      datat-testid="sidebar"
      class="sidebar"
      style="width: 289px;">
      <div class="logo-sec">
        <img class="logo"
             data-testid="sidebar-logo"
             @click="goToDashboard"
             :src="brandLogoWhite"
             alt="logo">
      </div>

      <div class="account-sec" :class="{'active': $route.path === '/account/all'}" data-testid="account-sec">
        <p v-if="getSelectedAccount.type !== 'personalChecking'" class="account_name" @click="viewAll">
          {{ getSelectedBusinessName }} <span class="icon-arrow-right" />
        </p>
        <p v-else class="account_name" @click="viewAll">
          {{ getPersonDetails.firstName + ' ' +getPersonDetails.lastName }} <span class="icon-arrow-right" />
        </p>
        <!-- <div data-testid="account-sec-link" class="go-to" @click="viewAll">
          {{ $t('side_menu_viewAll') }} <span class="icon-arrow-right" />
        </div> -->
      </div>
          
      <el-menu
        :default-active="$route.path"
        class="el-menu-vertical-demo"
        router>
        <el-menu-item index="/dashboard/home" @click="hideSidebar">
          <i class="iconMenu-overview" />
          <span>{{ $t('side_menu_overview') }}</span>
        </el-menu-item>
        <el-menu-item index="/transaction/list" @click="hideSidebar">
          <i class="icon-transactions-new" />
          <span>{{ $t('side_menu_transactions') }}</span>
        </el-menu-item>
        <el-menu-item id="accounting-menu" class="el-submenu">
          <div class="accounting-link">
            <v-icon>mdi-account-cash-outline</v-icon>
            <span>{{ $t('side_menu_accounting') }}</span>
            <span class="icon-arrow-right ml-auto" />
          </div>
          <ul role="menu" class="el-menu el-menu--inline" style="display: none;">
            <el-menu-item index="/accounting/accounts" @click="hideSidebar">
              {{ $t('side_menu_accounts') }}
            </el-menu-item>
            <el-menu-item index="/accounting/items" @click="hideSidebar">
              {{ $t('side_menu_items') }}
            </el-menu-item>
            <el-menu-item index="/accounting/bills" @click="hideSidebar">
              {{ $t('side_menu_bills') }}
            </el-menu-item>
            <el-menu-item index="/accounting/chart-of-accounts" @click="hideSidebar">
              {{ $t('side_menu_chart_accounts') }}
            </el-menu-item>
            <el-menu-item index="/accounting/vendors" @click="hideSidebar">
              {{ $t('vendors') }}
            </el-menu-item>
            <!-- <el-menu-item index="/accounting/customers">
              {{ $t('customers') }}
            </el-menu-item> -->
            <!-- <el-menu-item index="/accounting/invoices">
              {{ $t('side_menu_invoices') }}
            </el-menu-item> -->
            <el-menu-item index="/accounting/settings" @click="hideSidebar">
              {{ $t('side_menu_settings') }}
            </el-menu-item>
          </ul>
        </el-menu-item>
        <!-- <el-submenu index="3" id="accounting-menu">
          <template slot="title">
            <v-icon>mdi-account-cash-outline</v-icon>
            <span>{{ $t('side_menu_accounting') }}</span>
          </template>
        </el-submenu> -->
        <el-menu-item index="/card/list" @click="hideSidebar">
          <i class="iconMenu-card" />
          <span>{{ $t('side_menu_cards') }}</span>
        </el-menu-item>
        <el-menu-item index="/fund" @click="hideSidebar">
          <i class="iconMenu-transfer" />
          <span>{{ $t('transfer_money') }}</span>
        </el-menu-item>
        <el-menu-item index="/contact/list" @click="hideSidebar">
          <i class="el-icon-notebook-2" />
          <span>Contacts</span>
        </el-menu-item>
        <el-menu-item index="/send" @click="hideSidebar">
          <i class="iconMenu-send" />
          <span>{{ $t('send_money') }}</span>
        </el-menu-item>
      </el-menu>

      <div class="menu-footer"
           v-if="!getIsMobileLayout"
           @click="goToProfile">
        <div class="initialsInfoLight">
          <span>{{ shortName }}</span>
        </div>
        <div class="user-name">
          {{ loggedInUser.firstName + ' ' +loggedInUser.lastName }}
        </div>
        <span class="icon-arrow-right" />
      </div>
    </el-aside>
  
    <Drawer class="default-drawer" :components="userProfileRoutes" :show.sync="showUserProfile" v-if="showUserProfile" />
    <Drawer class="default-drawer" :components="businessDetailsRoutes" :show.sync="showBusinessDetails" v-if="showBusinessDetails" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Drawer from '@/components/Drawer.vue';
export default {
  components: {
    Drawer
  },
  data() {
    return {
      showUserProfile: false,
      showBusinessDetails: false,
      userProfileRoutes: [
        {
          name: 'UserProfile',
          default: true,
          component: () => import('@m/dashboard/views/UserProfile.vue')
        },
        {
          name: 'EditUserAddress',
          component: () => import('@m/dashboard/views/EditUserAddress.vue')
        },
        {
          name: 'AddBusiness',
          component: () => import('@m/dashboard/views/AddBusiness.vue'),
        },
        {
          name: 'Managers',
          component: () => import('@m/dashboard/views/managers/Managers.vue'),
        },
        {
          name: 'AddManager',
          component: () => import('@m/dashboard/views/managers/AddManager.vue'),
        },
        {
          name: 'EditManager',
          component: () => import('@m/dashboard/views/managers/EditManager.vue'),
        },
        {
          name: 'BusinessDetails',
          component: () => import('@m/dashboard/views/BusinessDetails.vue'),
        },
        {
          name: 'SetupAccount',
          component: () => import('@m/dashboard/views/SetupAccount.vue'),
        },
      ],
      businessDetailsRoutes: [
        {
          name: 'BusinessDetails',
          default: true,
          component: () => import('@m/business/views/BusinessDetails.vue')
        },
        {
          name: 'EditBusinessAddress',
          component: () => import('@m/business/views/EditBusinessAddress.vue')
        }
      ],
    }
  },
  computed: {
    ...mapGetters(['getIsSidebarVisible', 'getIsMobileLayout']),
    ...mapGetters('kyb', ['getSelectedBusiness']),
    ...mapGetters('user',['getPersonDetails']),
    ...mapGetters('account', ['isBusinessAccountType', 'getSelectedAccount']),
    shortName() {
      let name = '';
      if(this.loggedInUser.firstName) {
        name = this.loggedInUser.firstName[0];
      }
      if(this.loggedInUser.lastName) {
        name+=this.loggedInUser.lastName[0];
      }
      return name;
    },
    getSelectedBusinessName() {
      if(this.getSelectedBusiness != null) {
        return this.getSelectedBusiness.legalName;
      }
      return ''
    },
    setDrawerSize() {
      return this.getIsMobileLayout ? '100%': '30%';
    },
    loggedInUser(){
      return this.$store.getters['user/isPiloting'] ? this.$store.getters['user/getPilotUser'] : this.getPersonDetails;
    }
  },
  methods: {
    ...mapMutations(['setIsSidebarVisible']),
    viewAll() {
      window.analytics && window.analytics.track('homeViewAllAccounts');
      this.$router.push('/account/all');
      this.hideSidebar();
    }, 
    goToProfile() {
      window.analytics && window.analytics.track('homeUserDetails');
      this.showUserProfile = true;
    },
    goToBusiness() {
      window.analytics && window.analytics.track('homeEntityDetails');
      this.showBusinessDetails = true;
    },
    hideSidebar() {
      this.setIsSidebarVisible(false);
    }
  }
}
</script>
<style lang="scss" scoped>
    .sidebar-backdrop {
      position: fixed;
      inset: 0;
      z-index: 999;
    }
    .sidebar {
        background: var(--branding-primary);
        width: 289px;
        min-height: 670px;
        top: 0;
        left: 0;
        min-height: 100vh;
        //background-color: #FFFFFF;
        border-right: 1px solid #E8EBEE;
        overflow-x: hidden;
        position: fixed;
         z-index: 1000;
        .logo-sec {
          background: var(--branding-primary);
            max-height: 105px;
            //min-height: 120px;
            //border-bottom: 1px solid #E8EBEE;
            padding: 28px 24px 48px 24px;
            text-align: center;
            img {
                max-width: 245px;
                max-height: 65px;
                min-height: 40px;
            }
        }
        .el-menu {
            background: var(--branding-primary);
            border-right: none;
        }
        .menu-footer {
            display: flex;
            align-items: center;
            height: 79px;
            width: 289px;
            border-top: 1px solid var(--shifl-pressed-blue);
            padding: 12px;
            position: absolute;
            bottom: 0;
            @media (max-height: 670px) {
              position: relative;
            }
            .initialsInfoLight {
                color: var(--branding-primary);
            }
            .user-name {
                color: #FFFFFF;
                font-size: 14px;
                line-height: 18px;
                padding: 15px 16px;
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-right: 16px;
            }
            .icon-arrow-right {
                font-size: 14px;
                color: var(--grey-1);
                position: absolute;
                right: 16px;
            }
        }
        .el-submenu__title:hover {
          color: red;
        }
        .el-submenu {
          &:hover, &:focus {
            background-color: var(--shifl-pressed-blue);
          }
          i {
            color: #FFFFFF;
            font-size: 18px;
          }
          .el-submenu__title {
            span {
              margin-left: 15px;
              font-size: 18px;
            }
          }
          .el-menu-item.is-active {
            background-color: var(--shifl-pressed-blue);
            border-left: 10px solid #039ddb;
          }
          
          .el-menu-item {
            &:hover, &:focus, &:active {
              background-color: var(--shifl-pressed-blue);
              border-left: 10px solid #039ddb;
            }
            padding-left: 24px;
            border-left: 10px solid transparent;
            color: #FFFFFF;
          }
          span {
            color: #FFFFFF;
          }
        }
        .el-menu-item {
            height: 51px;
            line-height: 51px;
            margin-bottom: 15px;
            font-size: 18px;
            &:hover, &:focus {
              background-color: var(--shifl-pressed-blue);
            }
            i {
                color: #FFFFFF;
            }
            span {
              color: #FFFFFF;
              margin-left: 15px;
            }
            &.is-active {
                background-color: var(--shifl-pressed-blue);
                color: #FFF;
                i, span {
                    color: #FFF;
                }
            }
            
        }
        @media (max-width: 992px) {
          transform: translate3d(-100%, 0, 0);
          transition: transform 0.3s;
          .is-sidebarvisible & {
            transform: translate3d(0, 0, 0);
          }
        }

    }
    
    .account-sec {
        background: var(--branding-primary);
        color: white;
        border-bottom: 1px solid var(--shifl-pressed-blue);
        padding: 19px 32px 14px 24px;
        //margin-bottom: 8px;
        
        .account_name {
          cursor: pointer;
          font-weight: 600;
          font-size: 20px;
          padding-bottom: 8px;
          display: flex;
          align-items: center;
          
          span {
            padding-left: 24px;
            font-size: 14px;
            margin-left: auto;
            color: #FFFFFF;
          }
        }
      &.active{
        background: var(--shifl-pressed-blue) !important;
      }
    }
    .go-to {
        color: var(--grey-1);
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 15px;
        span {
            padding-left: 10px;
            font-size: 12px;
        }
    }
</style>
<style lang="scss">
  .el-submenu__title {
    &:hover, &:active {
      background: var(--shifl-pressed-blue) !important;
    }
  }
  .el-submenu {
    .el-menu {
      background: var(--branding-primary);
    }
    .el-submenu__title i {
      color: #fff !important;
      font-size: 16px;
    }
  }
  #accounting-menu {

    .accounting-link {
      display: flex;
      align-items: center;

      .ml-auto {
        margin-left: auto !important;
      }
    }

    .el-menu {
      display: none;
      // .el-menu-item {
      //   margin-bottom: 0px;
      // }
    }

    .is-mobile & {
      &:hover .el-menu {
        position: fixed;
        left: 4px;
        top: 301px;
        width: 280px;
        display: block !important;
        z-index: 1;
        border: 1px solid #498ca9;
      }  
    }

    &:hover .el-menu {
      position: fixed;
      left: 287px;
      top: 303px;
      display: block !important;
    }
  }
</style>