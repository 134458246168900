/**
 * @typedef {import('./state').contactState} contactState
 */
import state from './state';
import {contactService} from '@/services/solidfi';
/** @type {import('axios').AxiosInstance} */
export default {
  namespaced: true,
  state,
  getters: {
    /**
     * get contacts state
     * @param {contactState} state
     * @return {contactState['contacts']}
     */
    getContacts(state) {
      return state.contacts;
    },
    /**
     * get create contact data
     * @param {contactState} state
     * @return {contactState['createContactData']}
     */
    getCreateContactData(state) {
      return state.createContactData;
    },
    /**
     * get selected contact data
     * @param {contactState} state
     * @return {contactState['selectedContact']}
     */
    getSelectedContactData(state) {
      return state.selectedContact;
    },
    /**
     * get self contacts state
     * @param {contactState} state
     * @return {contactState['selfContacts']}
     */
    getSelfContacts(state) {
      return state.selfContacts;
    }
  },
  mutations: {
    /**
     * set contacts state
     * @param {contactState} state
     * @param {contactState['contacts']} payload
     */
    setContacts(state, payload) {
      state.contacts = payload;
    },
    /**
     * update create contact data
     * @param {contactState} state
     * @param {contactState['createContactData']} payload
     */
    updateCreateContactData(state, payload) {
      state.createContactData = payload;
    },
    /**
     * update selected contact data
     * @param {contactState} state
     * @param {contactState['createContactData']} payload
     */
    updateSelectedContactData(state, payload) {
      state.selectedContact = payload;
    },
    /**
     * update self contacts
     * @param {contactState} state
     * @param {contactState['selfContacts']} payload
     */
    updateSelfContacts(state, payload) {
      state.selfContacts = payload;
    }
  },
  actions: {
    /**
     * list all contacts
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} accountId
     * @returns {Promise<contactState['contacts']>}
     */
    listAllContacts({ commit, state }, {accountId, limit = 25, offset = 0, query}) {
      return new Promise((resolve, reject) => {
        let params = {limit,offset, type: 'others'};
        if (query) params.query = query;
        contactService.contacts(accountId,params)
          .then(({ data }) => {
            let d = {};
            if(offset === 0) {
              d = data;
            } else {
              const contacts = {...state.contacts};
              d = {
                data: [...contacts.data,...data.data],
                total: data.total
              }
            }
            commit('setContacts', d);
            resolve(d);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * create contact
     * @returns {Promise<contactState['createContactData']>}
     */
    createContact(_, payload) {
      return new Promise((resolve, reject) => {
        contactService.createContact(payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * get contact details
     * @param {*} _
     * @param {string} contactId
     */
    getContactDetails(_, contactId) {
      return new Promise((resolve, reject) => {
        contactService.contact(contactId)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * update contact details
     * @param {*} _
     * @param {Object} payload
     */
    updateContact(_, payload) {
      return new Promise((resolve, reject) => {
        const contactId = payload.id;
        delete payload.id;
        contactService.updateContact(contactId, payload)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * archive contact
     * @param {*} _
     * @param {string} contactId
     */
    archiveContact(_, contactId) {
      return new Promise((resolve, reject) => {
        contactService.deleteContact(contactId)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * list self contacts
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} accountId
     * @returns {Promise<contactState['contacts']>}
     */
    listSelfContacts({ commit }, accountId) {
      return new Promise((resolve, reject) => {
        contactService.contacts(accountId,{type: 'self'})
          .then(({ data }) => {
            commit('updateSelfContacts', data.data);
            resolve(data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    }
  }
};
