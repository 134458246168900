export default{
  isQBOEnabled(state) {
    return state.companyInformation && Boolean(state.companyInformation?.accounting_company?.qbo_realm_id) && state.QBOInformation ? 1 : 0;
  },

  homeCurrency(state) {
    return state.companyInformation?.accounting_details?.currency || 'USD';
  },

  getDEtypes() {
    return {
      'double-entry::types.current_asset': 'Current Asset',
      'double-entry::types.fixed_asset': 'Fixed Asset',
      'double-entry::types.inventory': 'Inventory',
      'double-entry::types.non_current_asset': 'Non-current Asset',
      'double-entry::types.prepayment': 'Pre-Payment',
      'double-entry::types.bank_cash': 'Bank & Cash',
      'double-entry::types.current_liability': 'Current Liability',
      'double-entry::types.liability': 'Liability',
      'double-entry::types.non_current_liability': 'Non-current Liability',
      'double-entry::types.depreciation': 'Depreciation',
      'double-entry::types.direct_costs': 'Direct Costs',
      'double-entry::types.expense': 'Expense',
      'double-entry::types.revenue': 'Revenue',
      'double-entry::types.sales': 'Sales',
      'double-entry::types.other_income': 'Other Income',
      'double-entry::types.equity': 'Equity',
      'double-entry::types.tax': 'Tax',
    }
  }
}