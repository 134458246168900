import Accounting from './index.vue';

const routes = [
  {
    path: '/accounting',
    name: 'Accounting',
    component: Accounting,
    children: [
      {
        path: 'bills',
        name: 'Bills',
        component: () => import('./views/Bills.vue'),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'bills/:id',
        name: 'BillDetails',
        component: () => import('./views/BillDetails.vue'),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'expenses/:id',
        name: 'ExpenseDetails',
        component: () => import('./views/ExpenseDetails.vue'),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'items',
        name: 'Items',
        component: () => import('./views/ItemsAndAccountBased.vue'),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'invoices',
        name: 'Invoices',
        component: () => import('./views/Invoices.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true,
        }
      },
      {
        path: 'invoices/:id',
        name: 'InvoiceDetails',
        component: () => import('./views/InvoiceDetails.vue'),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'currencies',
        name: 'Currencies',
        component: () => import('./views/Currencies.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true,
        }
      },
      // {
      //   path: 'payments',
      //   name: 'Payments',
      //   component: () => import('./views/Payments.vue'),
      //   meta: {
      //     requiresAuth: true,
      //     preventBrowserBack: true,
      //   }
      // },
      {
        path: 'vendors',
        name: 'Vendors',
        component: () => import('./views/Vendors.vue'),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'customers',
        name: 'Customers',
        component: () => import('./views/Customers.vue'),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'accounts',
        name: 'Accounts',
        component: () => import('./views/Accounts.vue'),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'chart-of-accounts',
        name: 'ChartOfAccounts',
        component: () => import('./views/ChartAccounts.vue'),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('./views/Settings.vue'),
        meta: {
          requiresAuth: true,
        }
      },
    ]
  }
];

export default routes;
