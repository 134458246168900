/**
 * @typedef {import('./state').transactionState} transactionState
 */
import state from './state';
import {transactionService} from '@/services/solidfi';
import store from '@/store';
/** @type {import('axios').AxiosInstance} */

export default {
  namespaced: true,
  state,
  getters: {
    /**
     * get recent transactions
     * @param {transactionState} state
     */
    getRecentTransactions(state) {
      return state.recentTransactions;
    },
    /**
     * get all transactions
     * @param {transactionState} state
     */
    getAllTransactions(state) {
      return state.transactions;
    },
    /**
     * get selected transaction
     * @param {transactionState} state
     */
    getSelectedTransaction(state) {
      return state.selectedTransaction;
    },
    /**
     * get filters
     * @param {transactionState} state
     */
    getFilters(state) {
      return state.filters;
    }
  },
  mutations: {
    /**
     * set transactions
     * @param {transactionState} state
     * @param {transactionState['transactions']} payload
     */
    setTransactions(state, payload) {
      if (payload.action == 'create') {
        state.transactions = payload;
      } else {
        state.transactions.total = payload.total;
        state.transactions.data.push(...payload.data);
      }
      store.commit('account/setAvailableBalance',payload.data[0].balance)
    },
    /**
     * set recent transactions
     * @param {transactionState} state
     * @param {transactionState['recentTransactions']} payload
     */
    setRecentTransactions(state, payload) {
      state.recentTransactions = payload;
      if (payload[0]?.balance) store.commit('account/setAvailableBalance',payload[0].balance)
    },
    /**
     * set selected transactions
     * @param {transactionState} state
     * @param {transactionState['transactions']} payload
     */
    setSelectedTransaction(state, payload) {
      state.selectedTransaction = payload;
    },
    /**
     * update transaction filters
     * @param {transactionState} state
     * @param {transactionState['filters']} payload
     */
    updateFilters(state, payload) {
      state.filters = payload;
    }
  },
  actions: {
    /**
     * get recent transactions
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {Object} param1
     * @param {string} param1.accountId
     * @param {number} param1.limit
     * @param {number} param1.offset
     * @returns {Promise<transactionState['transactions']>}
     */
    fetchAllTransactions(
      { commit },
      {
        limit,
        offset,
        accountId,
        contactId,
        cardId,
        startDate,
        endDate,
        minAmount,
        maxAmount,
        txnType,
        cardIdFromFilter,
        format,
        query
      }
    ) {
      return new Promise((resolve, reject) => {
        let params = {limit, offset};
        if (contactId) {
          params.contactId = contactId;
        }
        if (cardId) {
          params.cardId = cardId;
        } else if (cardIdFromFilter) {
          params.cardId = cardIdFromFilter;
        }
        if (startDate) {
          params.startDate = startDate;
        }
        if (endDate) {
          params.endDate = endDate;
        }
        if (minAmount && minAmount !== '0.00' && minAmount != '0') {
          params.minAmount = minAmount;
        }
        if (maxAmount && maxAmount !== '0.00' && maxAmount != '0') {
          params.maxAmount = maxAmount;
        }
        if (txnType) {
          params.txnType = txnType;
        }
        if (format) {
          params.export = format;
        }
        if (query) {
          params.query = query;
        }
        transactionService.all(accountId,params)
          .then(({ data }) => {
            if (format !== 'pdf') {
              let action = offset === 0 ? 'create' : 'update';
              commit('setTransactions', {
                action,
                ...data
              });
            }
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * get recent transactions
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {Object} param1
     * @param {string} param1.accountId
     * @param {number} param1.limit
     * @param {number} param1.offset
     * @returns {Promise<transactionState['transactions']>}
     */
    fetchRecentTransactions({ commit }, { accountId }) {
      return new Promise((resolve, reject) => {
        transactionService.all(accountId, {limit: 3, offset: 0})
          .then(({ data }) => {
            commit('setRecentTransactions', data.data);
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * fetch transactions list
     * @param {*} param0 
     * @param {*} param1 
     * @returns 
     */
    fetchTransaction({ commit }, { accountId, transactionId, format, transaction = null }) {
      return new Promise((resolve, reject) => {
        let params = {
          transaction
        }
        if (format) {
          params.export = format
        }
        transactionService.get(accountId,transactionId,params)
          .then(({ data }) => {
            if (format !== 'pdf') {
              commit('setSelectedTransaction', data);
            }
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    }
  }
};
