import Quickbooks from './index.vue';

const routes = [
  {
    path: '/quickbooks',
    name: 'Quickbooks',
    component: Quickbooks,
    children: [
      {
        path: 'signin',
        name: 'QuickbooksSignin',
        component: () => import('./views/Signin.vue'),
        meta: {
          requiresAuth: false,
          layout: 'vuetify',
        }
      },
      {
        path: 'token',
        name: 'QuickbooksToken',
        component: () => import('./views/Token.vue'),
        meta: {
          requiresAuth: false,
          preventBrowserBack: true,
          layout: 'vuetify',
        }
      },
      {
        path: 'vendors',
        name: 'QuickbooksVendors',
        component: () => import('./views/Vendors.vue'),
        meta: {
          requiresAuth: false,
          preventBrowserBack: true
        }
      },
      {
        path: 'items',
        name: 'QuickbooksItems',
        component: () => import('./views/Items.vue'),
        meta: {
          requiresAuth: false,
          preventBrowserBack: true
        }
      },
    //   {
    //     path: 'details', 
    //     name: 'TransactionDetails',
    //     component: () => import('./views/TransactionDetails.vue'),
    //     meta: {
    //       requiresAuth: true,
    //       preventBrowserBack: true
    //     }
    //   },
    //   {
    //     path: 'filter',
    //     name: 'TransactionFilter',
    //     component: () => import('./views/TransactionFilter.vue'),
    //     meta: {
    //       requiresAuth: true,
    //       preventBrowserBack: true
    //     }
    //   }
    ]
  }
];

export default routes;
