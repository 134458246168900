/**
 * @typedef {Object} CheckDepositState
 * @property { import('@m/contact/store/state').contactState['selectedContact']} selectedContact
 * @property {Object} successDepositData
 * @property {Object} checkDepositFormData
 * @property {Array<checkDeposit>} checkDepositList
 */
export default {
  selectedContact: null,
  successDepositData: null,
  checkDepositFormData: null,

  /**
   * @typedef {Object} checkDeposit
   * @property {String} accountId
   * @property {Number} amount
   * @property {String} contactId
   * @property {String} createdAt
   * @property {String} description
   * @property {String} id
   * @property {String} modifiedAt
   * @property {String} status
   */
  checkDepositList: [],
  checkDeposit: null,
};
