import store from '@/store';
import checkDepositStore from '../store/index';
export function registerStore() {
  if (!checkDepositStore.regsitered) {
    store.registerModule('checkDeposit', checkDepositStore, {
      preserveState: !!store.state['checkDeposit'],
    });
    checkDepositStore.regsitered = true;
  }
}

export default function init() {
  //do initial work
  registerStore();
}
