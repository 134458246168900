
const updateAccountingCompany = (state, payload) => {
  state.companyInformation = payload;
}

const updateQBO = (state, payload) => {
  state.QBOInformation = payload;
}

const updateQuickbooksItemList = (state,payload) => {
  state.itemList = payload
}

// const updateQuickbooksVendorList = (state,payload) => {
//   state.vendorLists = payload
// }

const updateAkauntingExpenseAccountLists = (state, payload) => {
  state.expenseAccountLists = payload;
}

const updateAkauntingIncomeAccountLists = (state, payload) => {
  state.incomeAccountLists = payload;
}

const updateQBOIncomeAccountLists = (state, payload) => {
  state.QBOIncomeAccountLists = payload;
}

const updateQBOExpenseAccountLists = (state, payload) => {
  state.QBOExpenseAccountLists = payload;
}

const updateQBOInventoryIncomeAccountLists = (state, payload) => {
  state.QBOInventoryIncomeAccountLists = payload;
}

const updateQBOInventoryExpenseAccountLists = (state, payload) => {
  state.QBOInventoryExpenseAccountLists = payload;
}

/* const updateServicesData = (state, payload) => {
  state.servicesData = payload;
} */

/* const updateIsServicesDataLoading = (state, payload) => {
  state.isServicesDataLoading = payload;
} */

const updateQBOAssetAccountLists = (state, payload) => {
  state.QBOInventoryAssetAccountLists = payload;
}

const updateisQBOAccountLoading = (state, payload) => {
  state.isQBOAccountLoading = payload;
}

const updateCurrencies = (state, payload) => {
  state.currencies = payload;
}

/* const updateBillsData = (state, payload) => {
  state.billsData = payload;
}

const updateBillDataLoading = (state, payload) => {
  state.isBillDataLoading = payload;
} */

const updateAccountingLastPath = (state, payload) => {
  state.accountingLastPath = payload;
}

const updateCheckingAccounting = (state, payload) => {
  state.isCheckingAccounting = payload;
}

const updateCheckingQBO = (state, payload) => {
  state.isCheckingQBO = payload;
}

export default {
  updateQuickbooksItemList,
  // updateQuickbooksVendorList,
  updateAkauntingExpenseAccountLists,
  updateAkauntingIncomeAccountLists,
  updateQBOIncomeAccountLists,
  updateQBOExpenseAccountLists,
  // updateServicesData,
  // updateIsServicesDataLoading,
  updateQBOAssetAccountLists,
  updateisQBOAccountLoading,
  updateQBOInventoryIncomeAccountLists,
  updateQBOInventoryExpenseAccountLists,
  updateCurrencies,
  updateAccountingCompany,
  updateQBO,
  // updateBillsData,
  // updateBillDataLoading,
  updateAccountingLastPath,
  updateCheckingAccounting,
  updateCheckingQBO,
}