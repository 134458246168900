import i18n from '@/setup/i18n';
import { getEnv } from '@/utils/env';
import axios from 'axios';

function redirectToLogin() {
  const router = require('@/router').default;
  router({}).push('/');
}

export default {
  setup: function () {
    const Axios = axios.create();
    Axios.defaults.baseURL = getEnv('VUE_APP_API_BASE_URL');
    Axios.defaults.headers.common.Accept = 'application/json';
    Axios.interceptors.request.use(function (config) {
      config.url = getEnv('VUE_APP_API_BASE_URL') + config.url;
      config.headers['sd-language'] = i18n.locale.includes('ru')
        ? 'ru_RU'
        : 'en';
      return Promise.resolve(config);
    });

    return Axios;
  },
  install: function (Vue) {
    const Axios = this.setup();
    /**
     * http method
     */
    Vue.prototype.$http = Axios;
    Vue.$http = Axios;

    this.createAxiosResponseInterceptor(Axios);
  },
  /**
   * get refresh token
   * @see {@link https://docs.sbx.wise.us/#b8247c7b-20af-4edc-ad9d-aeb17e268ba7}
   * @param {Object} Axios
   * @param {Function}
   * @returns {Promise<NewAccessToken>}
   */
  createAxiosResponseInterceptor: function (Axios) {
    const interceptorObj = Axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (!error.response) {
          return Promise.reject(error);
        }
        if (error.response.status !== 401) {
          return Promise.reject(error);
        }

        /*
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response
         */
        Axios.interceptors.response.eject(interceptorObj);

        const store = require('@/store');
        const authTokens = store.default.getters['user/getUserAuthToken'];
        if (authTokens) {
          const idToken = authTokens.idToken;
          const refreshToken = authTokens.refreshToken;

          return Axios.post('v1/auth/refresh', {
            clientId: getEnv('clientId'),
            refreshToken: refreshToken,
            idToken: idToken
          })
            .then(({ data }) => {
              data.refreshToken = refreshToken;
              store.default.commit('user/setUserAuthToken', data);
              error.response.config.headers.AUTHORIZATION =
                'Bearer ' + data.accessToken;
              Axios.defaults.headers.common.AUTHORIZATION =
                'Bearer ' + data.accessToken;
              let newURL = error.response.config.url.replace(
                getEnv('VUE_APP_API_BASE_URL'),
                ''
              );
              error.response.config.url = newURL;
              return Axios(error.response.config);
            })
            .catch((error) => {
              store.default.commit('user/setUserAuthToken', null);
              redirectToLogin();
              return Promise.reject(error);
            })
            .finally(() => {
              this.createAxiosResponseInterceptor(Axios);
            });
        } else {
          redirectToLogin();
        }
      }
    );
  }
};
