import store from '@/store';
import fundStore from '../store/index';

export function registerStore() {
  if (process.env.VUE_APP_ENV !== 'development') {
    store.registerModule('fund', fundStore, {
      preserveState: !!store.state['fund'],
    });
  } else {
    if (!fundStore.registered) {
      store.registerModule('fund', fundStore, {
        preserveState: !!store.state['fund']
      });
      fundStore.registered = true;
    }
  }
}

export default function init() {
  //do initial work
  registerStore();
}
