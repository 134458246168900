import { getBrandConfig, getBrandEnv } from '@/utils/env';

/**
 * @typedef {import('./state').userState} userState
 */
import state from './state';
import {personService, programService} from '@/services/solidfi';
import {Auth} from '@/services/auth';
import Vue from 'vue';
/** @type {import('axios').AxiosInstance} */


export default {
  namespaced: true,
  state,
  getters: {
    get_solid_auth_token(state) {
      return state.solid_auth_token
    },
    getAuthEmail(state) {
      return state.authEmail
    },
    /**
     *
     * @param {userState} state
     * @returns
     */
    getAuthToken(state) {
      return state.authToken
    },
    getAccessToken(state) {
      return state.authToken?.accessToken
    },
    /**
     * get person details state
     * @param {userState} state
     */
    getPersonDetails(state) {
      return state.personDetails;
    },
    /**
     * get user details state
     * @param {userState} state
     */
    getUserDetails(state) {
      return state.userDetails;
    },
    /**
     * get user auth token state
     * @param {userState} state
     */
    getUserAuthToken(state) {
      return state.authTokenDetails;
    },
    /**
     * check user logged in or not
     * @param {userState} state
     */
    isUserLoggedIn(state) {
      return state.authToken
        ? true
        : false;
    },
    /**
     * get program details
     * @param {userState} state
     * @return {userState['programDetails']} state
     */
    getProgramDetails(state) {
      return state.programDetails;
    },
    /**
     * get program details
     * @param {userState} state
     * @return {userState['programDetails']} state
     */
    getBrandColor(state) {
      return state.programDetails && state.programDetails.brand && state.programDetails.brand.primaryColor && state.programDetails.brand.primaryColor.length !== 0 ? state.programDetails.brand.primaryColor : getBrandConfig('primaryColor','#141414');
    },
    getPilotAccounts(state){
      return state.pilotAccounts
    },
    getPilot(state){
      return state.pilot
    },
    getPilotUser(state){
      return state.pilotUser
    },
    isPiloting(state){
      return !!state.pilot && !!state.pilotUser
    },
  },
  mutations: {
    set_solid_auth_token(state, payload) {
      state.solid_auth_token = payload
    },
    setAuthEmail(state, payload) {
      state.authEmail = payload
    },
    /**
     * update user details state
     * @param {userState} state
     * @param {userDetails} payload
     */
    setUserDetails(state, payload) {
      state.userDetails = payload;
    },
    /**
     * update user auth token state
     * @param {userState} state
     * @param {authToken} payload
     */
    setAuthToken(state, payload) {
      Vue.$serverRequest.defaults.headers.common.AUTHORIZATION = 'Bearer ' + payload.accessToken;
      state.authToken = payload;
    },
    /**
     * update user auth token state
     * @param {userState} state
     * @param {authTokenDetails} payload
     */
    setUserAuthToken(state, payload) {
      state.authTokenDetails = payload;
    },
    /**
     * update person details state
     * @param {userState} state
     * @param {personDetails} payload
     */
    setPersonDetails(state, payload) {
      state.personDetails = payload;
    },
    /**
     * update user otp state
     * @param {userState} state
     * @param {string} payload
     */
    updateUserOtp(state, payload) {
      state.userDetails.otp = payload;
    },
    /**
     * update program details
     * @param {userState} state
     * @param {userState['programDetails']} payload
     */
    setProgramDetails(state, payload) {
      state.programDetails = payload;
    },
    setPilotAccounts(state,payload){
      state.pilotAccounts = payload
    },
    setPilot(state,payload){
      state.pilot = payload
    },
    setPilotUser(state,payload){
      state.pilotUser = payload
    }
  },
  actions: {
    /**
     * user auth login
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} mobileNumber
     * @returns {Promise<userDetails>}
     */
    login({ commit }, mobileNumber) {
      return new Promise((resolve, reject) => {
        Auth.mobileLogin( {
          connection: 'sms',
          phone: mobileNumber,
          send: 'code',
          clientId: getBrandEnv('clientId')
        })
          .then(({ data }) => {
            commit('setUserDetails', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * verify otp
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {userState} param0.state
     * @param {Object} param1
     * @param {string} param1.otp
     * @returns {Promise<authTokenDetails>}
     */
    async verifyOtp({ commit, state }, { otp, idNumberLast4, programCode }) {
      let payload = {
        connection: 'sms',
        phone: state.userDetails.phone,
        code: otp,
        programCode,
        idNumberLast4,
        clientId: getBrandEnv('clientId')
      };
      try {
        const response = await Auth.verifyOtp(payload);
        commit('setAuthToken', response.data.data.token);
        return Promise.resolve(response.data)
      }catch (e) {
        const returnVal = e?.response?.data ?? e;
        return Promise.reject(returnVal)
      }
    },
    /**
     * get person details
     * @param {Object} param0
     * @param {Function} param0.commit
     * @returns {Promise<personDetails>}
     */
    getPerson({ commit },direct = false) {
      return new Promise((resolve, reject) => {
        let grabPerson = direct ? personService.person() : personService.person();
        grabPerson
          .then(({ data }) => {
            commit('setPersonDetails', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * update person details
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {Object} param1
     * @param {string} param1.personId
     * @param {userState['personDetails']} param1.person
     * @returns {Promise<personDetails>}
     */
    updatePerson({ commit }, { personId, person }) {
      return new Promise((resolve, reject) => {
        personService.update(personId,person)
          .then(({ data }) => {
            commit('setPersonDetails', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
    /**
     * logout
     * @param {Object} param0
     * @param {userState} param0.state
     * @param {Function} param0.commit
     * @returns
     */
    logout() {
      return new Promise((resolve, reject) => {
        Auth
          .logout()
          .then((data) => {
            // delete tokens
            this.reset();
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * get program config
     */
    getProgramConfig({ commit }, programId) {
      return new Promise((resolve, reject) => {
        programService.program(programId)
          .then(({ data }) => {
            commit('setProgramDetails', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    }
  }
};
