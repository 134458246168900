import BaseService from '../base.service'

let authService = class AuthService extends BaseService {

  constructor(){
    super();        
  }
    
  login(params= {}){
    let url = this.baseURL + '/login';        
    return super.post(url, params);
  }
    
  details(params= {}) {
    let url = this.baseURL + '/details';        
    return super.post(url, params);
  }

  savePerson(params = {}) {
    let url = this.baseURL + '/v1/save-person'
    return super.post(url, params);
  }

  verifyOtp(params = {}) {
    let url = this.baseURL + '/v1/otp/verify'
    return super.post(url, params);
  }

  resendOtp(params = {}) {
    let url = this.baseURL + '/v1/otp/resend'
    return super.post(url, params);
  }

  mobileLogin(params = {}) {
    let url = this.baseURL + '/v1/otp/mobile-login'
    return super.post(url, params);
  }

  logout(params){
    let url = this.baseURL + '/logout'
    return super.post(url, params);
  }

};

export default authService
