import store from '@/store';
import kycStore from '../store/index';

export function registerStore() {
  store.registerModule('kyc', kycStore, {
    preserveState: !!store.state['kyc']
  });
}

export default function init() {
  //do initial work
}
