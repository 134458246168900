import SolidService from './solid.service'

let businessService = class BusinessService extends SolidService {

  constructor() {
    super();
  }

  business() {
    let url = this.backendSolidBaseUrl + '/business';
    return super.get(url);
  }

  businessDetails($id) {
    let url = this.backendSolidBaseUrl + '/business/'+$id;
    return super.get(url);
  }

  businesses($person_id) {
    let url = this.backendSolidBaseUrl + '/business/list/' + $person_id;
    return super.get(url);
  }

  createBusiness($payload) {
    let url = this.backendSolidBaseUrl + '/business';
    return super.post(url, $payload);
  }

  updateBusiness($id, $payload) {
    let url = this.backendSolidBaseUrl + '/business/' + $id;
    return super.patch(url, $payload);
  }

  submitKYB($id, $payload) {
    let url = this.backendSolidBaseUrl + `/business/${$id}/kyb`;
    return super.post(url, $payload);
  }

  retrieveKYB($id) {
    let url = this.backendSolidBaseUrl + `/business/${$id}/kyb`;
    return super.get(url);
  }

  agreement($id) {
    let url = this.backendSolidBaseUrl + `/business/${$id}/agreement`;
    return super.get(url);
  }
};

export default businessService
