import PersonService from './person.service';
import ProgramService from './program.service'
import ContactService from './contact.service'
import BusinessService from './business.service'
import AccountService from '@/services/solidfi/account.service';
import CardService from '@/services/solidfi/card.service';
import SendService from '@/services/solidfi/send.service';
import ReceiveService from '@/services/solidfi/receive.service';
import TransactionService from '@/services/solidfi/transaction.service';
import MemberService from '@/services/solidfi/member.service';

const personService = new PersonService();
const programService = new ProgramService();
const contactService = new ContactService();
const businessService = new BusinessService();
const accountService = new AccountService();
const cardService = new CardService();
const sendService = new SendService();
const receiveService = new ReceiveService();
const transactionService = new TransactionService();
const memberService = new MemberService();

export {
  memberService,
  transactionService,
  receiveService,
  sendService,
  cardService,
  accountService,
  businessService,
  contactService,
  personService,
  programService
}
