import Pay from './index.vue';
const routes = [
  {
    path: '/pay',
    component: Pay,
    children: [
      {
        path: 'paymentType',
        name: 'PaymentType',
        component: () => import('./views/PaymentType.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: '',
        name: 'PayView',
        component: () => import('./views/Pay.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'intrabank',
        name: 'PayIntrabank',
        component: () => import('./views/Intrabank.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'ach',
        name: 'PayAch',
        component: () => import('./views/Ach.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'check',
        name: 'PayCheck',
        component: () => import('./views/Check.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      },
      {
        path: 'domestic-wire/address',
        name: 'DomesticWireAddress',
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        },
        component: () => import('./views/DomesticWire.vue')
      },
      {
        path: 'international-wire/address',
        name: 'InternationalWireAddress',
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        },
        component: () => import('./views/InternationalWire.vue')
      },
      {
        path: 'domestic-wire/account',
        name: 'DomesticWireAccount',
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        },
        component: () => import('./views/DomesticWireAccount.vue')
      },
      {
        path: 'international-wire/account',
        name: 'InternationalWireAccount',
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        },
        component: () => import('./views/InternationalWireAccount.vue')
      },
      {
        path: 'success',
        name: 'PaySuccess',
        component: () => import('./views/PaySuccess.vue'),
        meta: {
          requiresAuth: true,
          preventBrowserBack: true
        }
      }
    ]
  }
];

export default routes;
