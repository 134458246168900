import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import UserRoutes from '@m/user/routes';
import KycRoutes from '@m/kyc/routes';
import KybRoutes from '@m/kyb/routes';
import DashboardRoutes from '@m/dashboard/routes';
import Home from '@/views/Home.vue';
import AccountRoutes from '@m/account/routes';
import ContactRoutes from '@m/contact/routes';
import CardRoutes from '@m/card/routes';
import TransactionRoutes from '@m/transaction/routes';
import PayRoutes from '@m/pay/routes';
import FundRoutes from '@m/fund/routes';
import SendRoutes from '@m/send/routes';
import BusinessRoutes from '@m/business/routes';
import CheckDepositRoutes from '@m/check-deposit/routes';
import Quickbooks from '@m/quickbooks/routes';

import { isUserLoggedIn } from '@/utils/general';

import AccountingRoutes from '@m/accounting/routes';

Vue.use(VueRouter);

let isBackPage = false;
window.addEventListener('popstate', function (e) {
  var state = e.state;
  if (state !== null) {
    //load content with ajax
    isBackPage = true;
  }
});
const routes = [
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '',
        name: 'Home',
        redirect: 'Welcome',
        meta: {
          layout: 'login'
        },
      },
      {
        path: '/welcome',
        name: 'Welcome',
        meta: {
          layout: 'login'
        },
        component: () => import(/* webpackChunkName: "login" */ '@/views/Welcome')
      },
      {
        path: '/legal',
        name: 'Legal',
        meta: {
          layout: 'login'
        },
        component: () => import(/* webpackChunkName: "login" */ '@/views/Legal')
      },
      //modules
      ...UserRoutes,
      ...KycRoutes,
      ...KybRoutes,
      ...ContactRoutes,
      // ...HomeRoutes,
      ...DashboardRoutes,
      ...AccountRoutes,
      ...CardRoutes,
      ...TransactionRoutes,
      ...PayRoutes,
      ...FundRoutes,
      ...BusinessRoutes,
      ...CheckDepositRoutes,
      ...SendRoutes,

      //customs
      ...AccountingRoutes,
      ...Quickbooks,
    ]
  },
  {
    path: '/hellosignmobile',
    name: 'HelloSignMobile',
    component: () => import(/* webpackChunkName: "HelloSignMobile" */ '@/views/HelloSignMobile')
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Mobile')
  },
  {
    path: '*',
    redirect: 'Welcome',
  }
];

let appRouter = null;

const router = ({ mode = 'history', baseUrl = process.env.BASE_URL }) => {
  if (appRouter) {
    return appRouter;
  }

  appRouter = new VueRouter({
    mode,
    base: baseUrl,
    routes
  });

  appRouter.beforeEach((to, from, next) => {
    let loggedIn = isUserLoggedIn();

    if (
      to.matched.some((record) => record.meta.requiresAuth) &&
      !from.matched.some((route) => route.meta.preventBrowserBack)
    ) {
      if (!loggedIn) {
        next({
          path: '/'
        });
      } else {
        next();
      }
    } else {
      const isUserClickBack = store.getters['getIsUserClickBack'];
      if (
        from.matched.some((route) => route.meta.preventBrowserBack) &&
        loggedIn &&
        !isUserClickBack &&
        isBackPage
      ) {
        store.commit('updateLogoutPopupStatus', true);
        next(false);
      } else {
        next();
      }
    }
    isBackPage = false;
    store.commit('userClickedBack', false);
  });

  return appRouter;
};

export default router;
