import i18n from '@/setup/i18n';
import { getEnv } from '@/utils/env';
import axios from 'axios';
import store from '@/store';

function redirectToLogin() {
  const router = require('@/router').default;
  router({}).push('/');
}
export const Axios = axios.create();
Axios.defaults.baseURL = getEnv('VUE_APP_SHIFL_BASE_URL');
Axios.defaults.headers.common.Accept = 'application/json';
Axios.defaults.withCredentials = true

Axios.interceptors.request.use(function (config) {
  config.url = getEnv('VUE_APP_SHIFL_BASE_URL') + config.url;
  config.headers['sd-language'] = i18n.locale.includes('ru')
    ? 'ru_RU'
    : 'en';

  const token = store.getters['user/getAuthToken'];
  if (token?.accessToken){
    config.headers.common.AUTHORIZATION = 'Bearer ' + token.accessToken
  }
  return Promise.resolve(config);
});

export default {

  install: function (Vue) {
    Vue.prototype.$serverRequest = Axios;
    Vue.$serverRequest = Axios;

    this.createAxiosResponseInterceptor(Axios);
  },
  /**
     * get refresh token
     * @see {@link https://docs.sbx.wise.us/#b8247c7b-20af-4edc-ad9d-aeb17e268ba7}
     * @param {Object} Axios
     * @param {Function}
     * @returns {Promise<NewAccessToken>}
     */
  createAxiosResponseInterceptor: function (Axios) {
    Axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        console.log(error?.response?.data?.message)
        if (error?.response?.data?.message === 'Unauthenticated.' && error?.response?.status === 401) {
          store.reset()
          redirectToLogin()
        }
        return Promise.reject(error)
      }
    );
  }
};
