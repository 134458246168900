import SolidService from './solid.service'

export default class AccountService extends SolidService {

  constructor() {
    super();
  }
  
  get($id){
    const url = this.backendSolidBaseUrl+ '/account/' + $id;
    return super.get(url);
  }

  getAll($limit,$business_id,$person_id){
    let params = {};
    if ($limit) params.limit = $limit;
    if ($business_id) params.businessId = $business_id;
    else if ($person_id) params.personId = $person_id;
    const url = this.backendSolidBaseUrl+ '/account/list';
    return super.get(url,params);
  }

  create($payload){
    const url = this.backendSolidBaseUrl+ '/account';
    return super.post(url,$payload);
  }

  update($id,$payload){
    const url = this.backendSolidBaseUrl+ '/account/' + $id;
    return super.patch(url,$payload);
  }

  statements($id){
    const url = this.backendSolidBaseUrl+ `/account/${$id}/statement`;
    return super.get(url);
  }

  retrieveStatement($id,$year,$month,$type){
    const url = this.backendSolidBaseUrl+ `/account/${$id}/statement/${$year}/${$month}`;
    const responseType = $type === 'pdf' ? 'blob' : 'json';
    return super.get(url,{type: $type},{},responseType);
  }

  plaidToken($account_id){
    const url = this.backendSolidBaseUrl+ `/account/${$account_id}/plaid-token`
    return super.post(url);
  }

  plaidAccount($account_id,$params){
    const url = this.backendSolidBaseUrl+ `/account/${$account_id}/plaid-account`
    return super.post(url,$params);
  }

}
