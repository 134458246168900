import SolidService from '@/services/solidfi/solid.service';

export default class ReceiveService extends SolidService{
  constructor() {
    super();
  }

  ach($payload){
    const uri = this.backendSolidBaseUrl + '/receive/ach';
    return super.post(uri,$payload)
  }

  intrabank($payload){
    const uri = this.backendSolidBaseUrl + '/receive/intrabank';
    return super.post(uri,$payload)
  }

  check($payload){
    const uri = this.backendSolidBaseUrl + '/receive/check';
    return super.post(uri,$payload)
  }

  uploadCheckFiles($payload){
    const uri = this.backendSolidBaseUrl + `/receive/check/${$payload.id}/files`;
    return super.post(uri,$payload.data)
  }

  getUploadCheckFiles($transferId){
    const uri = this.backendSolidBaseUrl + `/receive/check/${$transferId}/files`;
    return super.get(uri)
  }

  allAchPulled($account_id){
    const uri = this.backendSolidBaseUrl + `/receive/ach/${$account_id}`;
    return super.get(uri);
  }
  allCheckDeposited($account_id){
    const uri = this.backendSolidBaseUrl + `/receive/check/${$account_id}`;
    return super.get(uri);
  }

  allIntrabankPulled($account_id) {
    const uri = this.backendSolidBaseUrl + `/receive/intrabank/${$account_id}`;
    return super.get(uri);
  }

}