import store from '@/store';
import { Notification, Message } from 'element-ui';
import { format, differenceInCalendarDays } from 'date-fns';
import { getCountryDetailsFromPhoneNumber } from './env';
/**
 * api error handler
 * @param {*} message
 */
export const apiErrorMessage = (message) => {
  Notification.closeAll();
  var msg = getErrorMessage(message);

  Notification({
    type: 'error',
    message:
      typeof msg === 'string'
        ? msg
        : 'Something went wrong and we could not process your request.',
    title: 'Error'
  });
};
/**
 * get error message from response
 * @param {*} error
 */
export const getErrorMessage = (error) => {
  var msg = error;
  if (typeof error === 'string') {
    msg = error;
  } else if (error.response && error.response.data) {
    return getErrorMessage(error.response.data);
  } else if (error.errorDesc) {
    msg = error.errorDesc;
  } else if (error.message) {
    msg = error.message;
  }
  return msg;
};
/**
 * check user is logged in or not
 */
export const isUserLoggedIn = () => {
  return store.getters['user/isUserLoggedIn'];
};
/**
 * format date
 */
export const formatDate = (date, f = 'yyyy-MM-dd') => {
  return format(new Date(date), f);
};

/**
 *
 * @param {*} contactObj
 * @returns
 */
export const dateDiff = (d1, d2) => {
  const date1 = typeof d1 === 'object' ? d1 : new Date(d1);
  const date2 = typeof d2 === 'object' ? d2 : new Date(d2);
  return differenceInCalendarDays(date1, date2);
};

/**
 * Provides Contact name basis on available firstName and lastName
 */
export const contactName = (contactObj) => {
  let name = '';
  if (contactObj) {
    if (contactObj.firstName) {
      name = contactObj.firstName;
    }
    if (contactObj.lastName) {
      if (name.length > 0) {
        name += ' ';
      }
      name += contactObj.lastName;
    }
  }
  return name;
};
/**
 * transaction amount
 * @param {string} amount
 * @returns {string}
 */
export const transactionAmount = (amount) => {
  if (amount.startsWith('-') || amount.startsWith('+')) {
    const index = 1;
    return amount.substring(0, index) + '' + currencyFormatter(amount.substr(index));
  }
  return '+' + currencyFormatter(amount);
};

export function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    const args = arguments;
    const that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export const formatPhoneNumber = (phoneNumber) => {

  const countryDetails = getCountryDetailsFromPhoneNumber(phoneNumber);
  const phoneNumberWithoutCode = (''+phoneNumber).replace(countryDetails.dial_code, '').replace(/ /g,'');
  console.log(phoneNumberWithoutCode)
  let mask = countryDetails.mask;

  let i = 0;
  const numbers = mask.split('').map((c) => {
    if(c === '#') {
      const returnValue = phoneNumberWithoutCode[i] || '';
      i++;
      return returnValue;
    }
    return c;
  }).join('');
  return countryDetails.dial_code + ' ' + numbers;
};

export const mask = (text, visibleDigit = 3) => {
  let str = '' + text;
  return '****' + str.substr(str.length - visibleDigit);
};

export const startAnalytics = (VUE_APP_segment_api_key) => {
  const analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console &&
        console.error &&
        console.error('Segment snippet included twice.');
    else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on'
      ];
      analytics.factory = function (t) {
        return function () {
          var e = Array.prototype.slice.call(arguments);
          e.unshift(t);
          analytics.push(e);
          return analytics;
        };
      };
      for (var t = 0; t < analytics.methods.length; t++) {
        var e = analytics.methods[t];
        analytics[e] = analytics.factory(e);
      }
      analytics.load = function (t, e) {
        var n = document.createElement('script');
        n.type = 'text/javascript';
        n.async = !0;
        n.src =
          'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js';
        var a = document.getElementsByTagName('script')[0];
        a.parentNode.insertBefore(n, a);
        analytics._loadOptions = e;
      };
      analytics.SNIPPET_VERSION = '4.1.0';
      analytics.load(VUE_APP_segment_api_key);

      analytics.ready(function () {
        if (window.Intercom) {
          // Hide Intercom on start as in Signup flow we dont require.
          window.Intercom('update', {'hide_default_launcher': true});
        }
        // var track = window.analytics.track;
        // window.analytics.track = function() {
        //     var arg = Array.prototype.slice.call(arguments);
        //     if(!arg[1]) {
        //       arg.splice( 1, 0, {} );
        //     }
        //     var browserDetails = getBrowserDetails();
        //     arg[1].browser = browserDetails.browserName + '_' + browserDetails.browserVersion;
        //     track.apply(null, arg)
        // }
      });
    }
};

export const updateBrand = () => {
  const brandColor = store.getters['user/getBrandColor'];
  const root = document.documentElement;
  root.style.setProperty('--branding-primary', brandColor);
};

export const textCopy = (textToBeCopied, callback) => {
  navigator.clipboard.writeText(textToBeCopied).then(
    function () {
      if (callback) {
        callback(null);
      }
    },
    function (e) {
      if (callback) {
        callback(e);
      }
    }
  );
};


export const networkOnline = (customClass = '') => {
  Message.closeAll();
  Message({
    message: 'Connection restored',
    type: 'success',
    customClass : typeof customClass === 'string' ? customClass : ''
  });
}

export const networkOffline = (customClass = '') => {
  Message({
    message: 'Please make sure you are connected to the internet',
    type: 'error',
    duration: 0,
    customClass : typeof customClass === 'string' ? customClass : ''
  });
}

export const currencyFormatter = (value) => {
  
  let number = value;

  if(typeof number === 'string') {
    number = number.replace('$', '');
    number = parseFloat(number);
  }

  if(isNaN(number)) {
    return value;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  
  return formatter.format(number);

};

export const checkOwnerShipInput = (rule, value, callback) => {
  let total = store.getters['member/getMembers'].map(mem => parseInt(mem.ownership)).reduce((a, b) => a + b)
  let currentOwnership = store.getters['member/getSelectedMember']?.ownership
  const remaining = 100 - (total - parseInt(isNaN(currentOwnership) ? 0 : currentOwnership))
  if (!value) {
    return callback(new Error('Required'));
  }
  setTimeout(() => {
    if (!Number.isInteger(parseInt(value))) {
      callback(new Error('Number only'));
    } else {
      if (value > remaining) {
        callback(new Error(`Must not be greater than ${remaining}%`));
      } else {
        callback();
      }
    }
  }, 300)
};