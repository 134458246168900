import store from '@/store';
import dashbaordStore from '../store/index';
let isRegistered = false;
export function registerStore() {
  if (!isRegistered) {
    store.registerModule('dashboard', dashbaordStore, {
      preserveState: !!store.state['dashboard'],
    });
    isRegistered = true;
  }
}

export default function init() {
  //do initial work
  registerStore();
}
