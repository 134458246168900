<template>
  <router-view />
</template>
<script>

import init from './setup';
init();
export default {
  name: 'Quickbooks',
  created() {
    // this.$store.dispatch('quickbooks/getQuickbooksItemsByCompany',1);
    // this.$store.dispatch('quickbooks/getQuickbooksVendorsByCompany',1);
    // console.log(this.$store)
  }
};
</script>
