import MainService from '../akaunting/main.service'

class AkauntingService extends MainService {

  constructor(){
    super();        
  }
    
  /* getItemsByCompany(params= {company_id:1}){
    let url = this.baseURL + '/api/akaunting/items';        
    return super.get(url, params);
  } */

  createAccountingCompany(payload) {
    return super.post(this.baseURL+'/v1/create-accounting-company', payload);
  }

  pingAccounting() {
    return super.get(this.baseURL+'/v1/accounting/ping');
  }

  pingQBO() {
    return super.get(this.baseURL+'/v1/accounting/ping-qbo');
  }

  getQBOLogin() {
    return super.post(this.baseURL + '/v1/quickbooks/connect', {});
  }
  
  signOutQBO() {
    return super.post(this.baseURL + '/v1/quickbooks/signout', {});
  }

  getBills(data) {
    return super.get(this.baseURL+'/v1/accounting/bills/list', data);
  }

  updateBill(id, payload) {
    return super.patch(this.baseURL+`/v1/accounting/bills/update/${id}`, payload);
  }

  // Customer API
  getCustomers(data){
    return super.get(this.baseURL+'/v1/accounting/customers/list', data);
  }

  deleteCustomer(id, data) {
    return super.remove(this.baseURL+'/v1/accounting/customers/delete/'+id, data);
  }
  
  createCustomer(payload){
    return super.post(this.baseURL+'/v1/accounting/customers/create', payload);
  }
  
  updateCustomer(payload){
    return super.patch(this.baseURL+`/v1/accounting/customers/update/${payload.id}`, payload)
  }
  //

  // Invoice API

  createInvoice(payload){
    return super.post(this.baseURL+'/v1/accounting/invoices/create', payload, { 'Content-Type' : 'multipart/form-data' });
  }

  // payload is type of FormData
  updateInvoice(id, payload){
    payload.append('_method', 'PATCH');
    return super.post(this.baseURL+`/v1/accounting/invoices/update/${id}`, payload, { 'Content-Type': 'multipart/form-data'});
  }

  getInvoices(data) {
    return super.get(this.baseURL+'/v1/accounting/invoices/list', data);
  }

  deleteInvoice(id, data) {
    return super.remove(this.baseURL+'/v1/accounting/invoices/delete/'+id, data);
  }
  
  getInvoice(id) {
    return super.get(this.baseURL+'/v1/accounting/invoices/show/'+id);
  }

  //vendors
  getVendors(data) {
    return super.get(this.baseURL+'/v1/accounting/vendors/list', data);
  }
  
  disableVendor(id, data) {
    return super.remove(this.baseURL+'/v1/accounting/vendors/delete/'+id, data);
  }

  // Bills API

  deleteBill(id, data) {
    return super.remove(this.baseURL+'/v1/accounting/bills/delete/'+id, data);
  }  
  
  getBill(id){
    return super.get(this.baseURL+'/v1/accounting/bills/show/'+id);
  }
  
  receiveBill(id, data) {
    return super.get(this.baseURL+'/v1/accounting/bills/'+id+'/received', data);
  }
  
  cancelBill(id, data) {
    return super.get(this.baseURL+'/v1/accounting/bills/'+id+'/cancelled', data);
  }
  
  getAkauntingItemCategory() {
    return super.get(this.baseURL+'/v1/akaunting/categories/item');
  }

  getAkauntingIncomeCategory() {
    return super.get(this.baseURL+'/v1/akaunting/categories/income');
  }

  // Vendor API

  createVendor(payload){
    return super.post(this.baseURL+'/v1/accounting/vendors/create', payload);
  }

  updateVendor(payload){
    return super.patch(this.baseURL+`/v1/accounting/vendors/update/${payload.id}`, payload)
  }

  getCurrencies(){
    return super.get(this.baseURL+'/v1/akaunting/currencies');
  }

  createBilling(payload){
    return super.post(this.baseURL+'/v1/accounting/bills/create', payload)
  }

  getAkauntingCategoryExpense(){
    return super.get(this.baseURL+ '/v1/akaunting/categories/expense' );
  }

  getQuickbooksItemsByCompany(company_id){
    return super.get(this.baseURL+'/akaunting/items?company_id='+company_id);
  }

  getAkauntingAccounts(){
    return super.get(this.baseURL+'/v1/akaunting/get-de-accounts');
  }

  getDEaccounts() {
    return super.get(this.baseURL+'/v1/accounting/de/accounts/get-accounts');
  }

  getQBOInventoryAssetAccounts(){
    return super.get(this.baseURL+'/v1/quickbooks/inventory-item-asset-accounts');
  }

  getQBOInventoryExpenseAccounts(){
    return super.get(this.baseURL+'/v1/quickbooks/inventory-item-expense-accounts')
  }

  getQBOInventoryIncomeAccounts(){
    return super.get(this.baseURL+'/v1/quickbooks/inventory-item-income-accounts');
  }

  getQBOIncomeAccounts(){
    return super.get(this.baseURL+'/v1/quickbooks/income-accounts');
  }

  getQBOExpenseAccounts(){
    return super.get(this.baseURL+'/v1/quickbooks/expense-accounts');
  }

  // Item API

  createItem(payload){
    return super.post(this.baseURL+'/v1/accounting/items/store', payload)
  }

  updateItem(payload){
    return super.patch(this.baseURL+`/v1/accounting/items/update/${payload.id}`, payload)
  }

  searchVendors(data) {
    return super.get(this.baseURL+'/v1/accounting/vendors/list', data);
  }

  searchItems(data) {
    return super.get(this.baseURL+'/v1/accounting/items/list', data);
  }

  getItems(data) {
    return super.get(this.baseURL+'/v1/accounting/items/list', data);
  }

  disableItem(id, data) {
    return super.remove(this.baseURL+'/v1/accounting/items/delete/'+id, data);
  }

  // Category Based Items API
  getCategoryBasedItems(data) {
    return super.get(this.baseURL+'/v1/accounting/items/qbo-account-based/list', data);
  }

  createCategoryBasedItem(payload){
    return super.post(this.baseURL+'/v1/accounting/items/qbo-account-based/store', payload)
  }

  updateCategoryBasedItem(payload){
    return super.patch(this.baseURL+`/v1/accounting/items/qbo-account-based/update/${payload.id}`, payload)
  }

  disableAccountBasedItem(id, data) {
    return super.patch(this.baseURL+'/v1/accounting/items/qbo-account-based/disable/'+id, data);
  }

  // Currency API

  getAccountingCurrencies(data){
    return super.get(this.baseURL+'/v1/accounting/currencies/list',data)
  }

  createCurrencyForm(payload){
    return super.post(this.baseURL+'/v1/accounting/currencies/create', payload);
  }

  updateCurrency(payload){
    return super.patch(this.baseURL+`/v1/accounting/currencies/update/${payload.id}`, payload)
  }

  deleteCurrency(id) {
    return super.remove(this.baseURL+`/v1/accounting/currencies/delete/${id}`);
  }

  getTerms(){
    return super.get(this.baseURL+'/v1/quickbooks/terms');
  }

  // Accounts API
  getAccounts(data) {
    return super.get(this.baseURL+'/v1/accounting/accounts/list', data);
  }
  
  createAccountForm(payload) {
    return super.post(this.baseURL+'/v1/accounting/accounts/create', payload);
  }
  
  updateAccountForm(payload) {
    return super.patch(this.baseURL+`/v1/accounting/accounts/update/${payload.id}`, payload);
  }
  
  deleteAccount(id, data) {
    return super.remove(this.baseURL+'/v1/accounting/accounts/delete/'+id, data);
  }
  
  enableAccount(id) {
    return super.get(this.baseURL+`/v1/accounting/accounts/${id}/enable`);
  }
  
  disableAccount(id) {
    return super.get(this.baseURL+`/v1/accounting/accounts/${id}/disable`);
  }
  
  getQBOAccountTypes(data) {
    return super.get(this.baseURL+`/v1/quickbooks/default-account-types`, data);
  }
  
  getQBOParentAccounts() {
    return super.get(this.baseURL+`/v1/quickbooks/accounts`);
  }
  
  getAccountTransactions(id) {
    return super.get(this.baseURL+`/v1/accounting/accounts/${id}/transactions`);
  }

  // Import QBO API
  importQBOVendors (data) {
    return super.get(this.baseURL+`/v1/accounting/import/qbo/vendors/check`, data);
  }

  importQBOItems (data) {
    return super.get(this.baseURL+`/v1/accounting/import/qbo/items/check`, data);
  }
  
  importQBOAccountBasedItems (data) {
    return super.get(this.baseURL+`/v1/accounting/import/qbo/account-based-items/check`, data);
  }
  
  fetchImportQBOBills(data) {
    return super.get(this.baseURL+`/v1/accounting/import/qbo/bills/check`, data);
  }
  
  importQBOBills(data) {
    return super.post(this.baseURL+`/v1/accounting/import/qbo/bills/run`, data);
  }
  
  fetchImportQBOBillPayments(data) {
    return super.get(this.baseURL+`/v1/accounting/import/qbo/bill-payments/check`, data);
  }
  
  importQBOBillPayments(data) {
    return super.post(this.baseURL+`/v1/accounting/import/qbo/bill-payments/run`, data);
  }
  
  getAutoSync() {
    return super.get(this.baseURL+`/v1/accounting/settings/qbo/auto-sync`);
  }
  
  setAutoSync(data) {
    return super.post(this.baseURL+`/v1/accounting/settings/qbo/auto-sync`, data);
  }

  // Category API
  getCategories(data) {
    return super.get(this.baseURL+`/v1/accounting/categories/list`, data);
  }
  
  createCategory(payload) {
    return super.post(this.baseURL+'/v1/accounting/categories/create', payload);
  }
  
  updateCategory(payload) {
    return super.patch(this.baseURL+`/v1/accounting/categories/update/${payload.id}`, payload);
  }
  
  deleteCategory(id, data) {
    return super.remove(this.baseURL+'/v1/accounting/categories/delete/'+id, data);
  }
  
  
  // Chart Account API
  
  getAccountAndTypes(data) {
    return super.get(this.baseURL+'/v1/accounting/de/accounts/get-accounts-and-types', data);
  }
  
  getChartAccounts(data) {
    return super.get(this.baseURL+`/v1/accounting/de/accounts/list`, data);
  }

  createChartAccount(payload) {
    return super.post(this.baseURL+'/v1/accounting/de/accounts/create', payload);
  }
  
  updateChartAccount(payload) {
    return super.patch(this.baseURL+`/v1/accounting/de/accounts/update/${payload.id}`, payload);
  }
  
  deleteChartAccount(id, data) {
    return super.remove(this.baseURL+'/v1/accounting/de/accounts/delete/'+id, data);
  }
  
  enableChartAccount(id) {
    return super.get(this.baseURL+`/v1/accounting/de/accounts/enable/${id}`);
  }
  
  disableChartAccount(id, data) {
    return super.get(this.baseURL+`/v1/accounting/de/accounts/disable/${id}`);
  }
  

};

export default new AkauntingService();