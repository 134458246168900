import store from '@/store';
import accountingStore from '../store/index';

export function registerStore() {
  if (process.env.VUE_APP_ENV !== 'development') {
    if (!accountingStore.registered) {
      store.registerModule('accounting', accountingStore, {
        preserveState: !!store.state['accounting'],
      });
      accountingStore.registered = true;
    }
  } else {
    if (!accountingStore.registered) {
      store.registerModule('accounting', accountingStore, {
        preserveState: !!store.state['accounting']
      });
      accountingStore.registered = true;
    }
  }
}

export default function init() {
  //do initial work
  console.info('Accounting Version: 1.0')
  registerStore();
}
