import axios from 'axios';
import { getEnv } from '@/utils/env';

import AkauntingService from '@/services/akaunting/akaunting.service';

const baseURL = getEnv('VUE_APP_SHIFL_BASE_URL')

const createAccountingCompany = async ({commit}, payload) => {
  try {
    const { data } = await AkauntingService.createAccountingCompany(payload)
    console.log(data);
    commit('updateAccountingCompany', data.data || null);
  } catch(error) {
    throw error;
  }
}

const getQuickbooksLoginUrl = async () => {
  try {
    const { data } = await AkauntingService.getQBOLogin();

    // Determine here if the returned data contains URL
    if(data.data && data.data.authUrl) {
      return data.data.authUrl;
    }

    return null;

  } catch(error) {
    throw error;
  }
}

const signOutQBO = async () => {
  try {
    const { data } = await AkauntingService.signOutQBO();
    return data;
  } catch(error) {
    throw error;
  }
}

const getAccounting = async ({commit}) => {
  try {
    commit('updateCheckingAccounting', true);
    const { data } = await AkauntingService.pingAccounting();
    commit('updateCheckingAccounting', false);
    return data;
  } catch(error) {
    commit('updateCheckingAccounting', false);
    throw error;
  }
}

const getQBOConnection = async ({commit}) => {
  try {
    commit('updateCheckingQBO', true);
    const { data } = await AkauntingService.pingQBO();
    commit('updateCheckingQBO', false);
    return data;
  } catch(error) {
    commit('updateCheckingQBO', false);
    throw error;
  }
}

const getAkauntingCategoryExpense = async({}) => {
  try {
    const { data } = await AkauntingService.getAkauntingCategoryExpense();
    return data?.data || [];
  } catch(error) {
    throw error;
  }
}

const getQuickbooksItemsByCompany = async ({commit},company_id) => {
  try {
    const res = AkauntingService.getQuickbooksItemsByCompany(company_id);
    const responseData = res.data;
      if(responseData.data.length > 0){
        commit('updateQuickbooksItemList',responseData.data);
      }
  } catch (error) {
    throw error;
  }
}

const getAkauntingAccounts = async ({commit}) => {
  try {
    const { data } = await AkauntingService.getAkauntingAccounts();
    if(data.data && data.data.income_accounts) {
      commit('updateAkauntingIncomeAccountLists', data.data.income_accounts);
    }
    if(data.data && data.data.expense_accounts) {
      commit('updateAkauntingExpenseAccountLists', data.data.expense_accounts);
    }
  } catch(error) {
    // Do nothing. Log this error in the future
  }
}

const getQBOInventoryAssetAccounts = async ({commit}) => {
  try {
    const response = await AkauntingService.getQBOInventoryAssetAccounts();
    commit('updateQBOAssetAccountLists', response.data || []);
  } catch(error) {
    // Do nothing
  }
}

const getQBOInventoryExpenseAccounts = async ({commit}) => {
  try {
    const response = await AkauntingService.getQBOInventoryExpenseAccounts();
    commit('updateQBOInventoryExpenseAccountLists', response.data || []);
  } catch(error) {
    // Do nothing
  }
}

const getQBOInventoryIncomeAccounts = async ({commit}) => {
  try {
    const response = await AkauntingService.getQBOInventoryIncomeAccounts()
    commit('updateQBOInventoryIncomeAccountLists', response.data || []);
  } catch(error) {
    // Do nothing
  }
}

const getQBOIncomeAccounts = async ({commit}) => {
  try {
    const response = await AkauntingService.getQBOIncomeAccounts();
    commit('updateQBOIncomeAccountLists', response.data || []);
  } catch (error) {
    console.log(error);
  }
}

const getQBOExpenseAccounts = async ({commit}) => {
  try {
    const response = await AkauntingService.getQBOExpenseAccounts();
    commit('updateQBOExpenseAccountLists', response.data || []);
  } catch (error) {
    console.log(error);
  }
}

const getQBOAccounts = async ({commit, dispatch}, QBOItemType) => {
  try {
    commit('updateisQBOAccountLoading', true);

    if(QBOItemType === 'Inventory') {
      await Promise.all([
        dispatch('getQBOInventoryAssetAccounts'),
        dispatch('getQBOInventoryExpenseAccounts'),
        dispatch('getQBOInventoryIncomeAccounts')
      ]);
    }

    if(['NonInventory', 'Service'].includes(QBOItemType)) {
       await Promise.all([
        dispatch('getQBOIncomeAccounts'),
        dispatch('getQBOExpenseAccounts'),
       ]);
    }

    commit('updateisQBOAccountLoading', false);

  } catch(error) {
    // Do nothing. Log this error in the future
    commit('updateisQBOAccountLoading', false);
  }
}

// Item API
const createItemForm = async ({}, payload) => {
  try {
    return await AkauntingService.createItem(payload)
  } catch(error) {
    throw error;
  }
}

const updateItemForm = async ({}, payload) => {
  try {
    return await AkauntingService.updateItem(payload)
  } catch(error) {
    throw error;
  }
}

const disableItem = async ({getters}, payload) => {
  try {
    const { data } = await AkauntingService.disableItem(payload, {qbo_enabled: getters.isQBOEnabled});
    return data;
  } catch(error) {
    throw error;
  }
}

const getItemsData = async ({}, payload) => {
  try {
    const response = await AkauntingService.getItems(payload);
    return response.data.data;
  } catch(error) {
    throw error;
  }
};

// Category Based Items API

const getCategoryBasedItemData = async ({}, payload) => {
  try {
    const response = await AkauntingService.getCategoryBasedItems(payload);
    return response.data.data;
  } catch(error) {
    throw error;
  }
}

const createCategoryBasedItemForm = async ({}, payload) => {
  try {
    return await AkauntingService.createCategoryBasedItem(payload)
  } catch(error) {
    throw error;
  }
}

const updateCategoryBasedItemForm = async ({}, payload) => {
  try {
    return await AkauntingService.updateCategoryBasedItem(payload)
  } catch(error) {
    throw error;
  }
}

const getQBOParentAccounts = async () => {
  try {
    const response = await AkauntingService.getQBOParentAccounts();
    return response.data;
  } catch (error) {
    throw error;    
  }
}

// Customers API

const getCustomersData = async ({}, payload) => {
  try {
    const response = await AkauntingService.getCustomers(payload);
    return response.data.data;
  } catch(error) {
    throw error;
  }
};
const createCustomerForm = async ({}, payload) => {
  try {
    return await AkauntingService.createCustomer(payload);
  } catch(error) {
    throw error;
  }
}

const updateCustomerForm = async ({}, payload) => {
  try {
    return await AkauntingService.updateCustomer(payload)
  } catch(error) {
    throw error;
  }
}

const deleteCustomer = async ({ getters }, payload) => {
  try {
    return await AkauntingService.deleteCustomer(payload, {qbo_enabled: getters.isQBOEnabled});
  } catch(error) {
    throw error;
  }
}

// Vendors API

const getVendorsData = async ({}, payload) => {
  try {
    const response = await AkauntingService.getVendors(payload);
    return response.data.data;
  } catch(error) {
    throw error;
  }
};

const createVendorForm = async ({}, payload) => {
  try {
    return await AkauntingService.createVendor(payload);
  } catch(error) {
    throw error;
  }
}

const updateVendorForm = async ({}, payload) => {
  try {
    return await AkauntingService.updateVendor(payload)
  } catch(error) {
    throw error;
  }
}

const getCurrencies = async ({commit}) => {
  try {
    const response = await AkauntingService.getCurrencies()
    commit('updateCurrencies', response?.data?.data?.data || []);
  } catch(error) {
    return;
  }
}

const disableVendor = async ({ getters }, payload) => {
  try {
    return await AkauntingService.disableVendor(payload, {qbo_enabled: getters.isQBOEnabled});
  } catch(error) {
    throw error;
  }
}

// Billing API
const createBillingForm = async ({}, payload) => {
  try {
    return await AkauntingService.createBilling(payload)
  } catch(error) {
    throw error;
  }
}

const getBillsData = async ({}, payload) => {
  try {
    const response = await AkauntingService.getBills(payload);
    return response.data.data;
  } catch(error) {
    throw error;
  }
};

const updateBillForm = async ({}, payload) => {
  try {
    return await AkauntingService.updateBill(payload.id, payload);
  } catch(error) {
    throw error;
  }
}

const deleteBill = async ({getters}, payload) => {
  try {
    return await AkauntingService.deleteBill(payload, {qbo_enabled: getters.isQBOEnabled});
  } catch(error) {
    throw error;
  }
}

const getBill = async ({}, payload) => {
  try {
    return await AkauntingService.getBill(payload);
  } catch(error) {
    throw error;
  }
}

const billReceived = async ({getters}, payload) => {
  try {
    return await AkauntingService.receiveBill(payload, {qbo_enabled: getters.isQBOEnabled});
  } catch(error) {
    throw error;
  }
}

const billCancelled = async ({getters}, payload) => {
  try {
    return await AkauntingService.receiveBill(payload, {qbo_enabled: getters.isQBOEnabled});
  } catch(error) {
    throw error;
  }
}

// Use when signing-in with QBO Oauth2
const setLastUrlPath = ({commit}, payload) => {
  commit('updateAccountingLastPath', payload);
}

// Invoice Form

const createInvoiceForm = async ({}, payload) => {
  try {
    return await AkauntingService.createInvoice(payload)
  } catch(error) {
    throw error;
  }
}

const getInvoicesData = async ({}, payload) => {
  try {
    const response =  await AkauntingService.getInvoices(payload);
    return response.data;
  } catch(error) {
    throw error;
  }
}

const updateInvoice  = async ({}, {id, payload}) => {
  try {
    return await AkauntingService.updateInvoice(id, payload);
  } catch(error) {
    throw error;
  }
}

const deleteInvoice = async ({getters}, payload) => {
  try {
    return await AkauntingService.deleteInvoice(payload, {qbo_enabled: getters.isQBOEnabled});
  } catch(error) {
    throw error;
  }
}

const getAccountingCurrencies = async ({}, payload) => {
  try {
    const response = await AkauntingService.getAccountingCurrencies(payload)
    return response.data.data
  } catch (error) {
    throw error;    
  }
}

const createCurrencyForm = async ({}, payload) => {
  try {
    const response =  await AkauntingService.createCurrencyForm(payload);
    return response.data;
  } catch(error) {
    throw error;
  }
}

const updateCurrencyForm = async ({}, payload) => {
  try {
    return await AkauntingService.updateCurrency(payload)
  } catch(error) {
    throw error;
  }
}

const deleteCurrency = async ({}, payload) => {
  try {
    return await AkauntingService.deleteCurrency(payload);
  } catch(error) {
    throw error;
  }
}

// Accounts

const getAccountsData = async ({}, payload) => {
  try {
    const response = await AkauntingService.getAccounts(payload)
    return response.data.data;
  } catch (error) {
    throw error;    
  }
}

const createAccountForm = async ({}, payload) => {
  try {
    const response =  await AkauntingService.createAccountForm(payload);
    return response.data;
  } catch(error) {
    throw error;
  }
}

const updateAccountForm = async ({}, payload) => {
  try {
    const response =  await AkauntingService.updateAccountForm(payload);
    return response.data;
  } catch(error) {
    throw error;
  }
}

const deleteAccount = async ({getters}, payload) => {
  try {
    return await AkauntingService.deleteAccount(payload, {qbo_enabled: getters.isQBOEnabled});
  } catch(error) {
    throw error;
  }
}

const disableAccountBasedItem = async ({getters}, payload) => {
  try {
    const { data } = await AkauntingService.disableAccountBasedItem(payload, {qbo_enabled: getters.isQBOEnabled});
    return data;
  } catch(error) {
    throw error;
  }
} 

const getQBOAccountTypes = async ({}, payload) => {
  try {
    const response = await AkauntingService.getQBOAccountTypes(payload);
    return response.data.data;
  } catch (error) {
    throw error;    
  }
}

// Categories
const getCategories = async ({}, payload) => {
  try {
    const response = await AkauntingService.getCategories(payload);
    return response.data?.data || [];
  } catch (error) {
    throw error;    
  }
}

// Chart of Accounts
const getChartAccountAndTypes = async ({}, payload) => {
  try {
    const response = await AkauntingService.getAccountAndTypes(payload);
    return response.data?.data?.data || {};
  } catch (error) {
    throw error;    
  }
}

const getChartAccounts = async ({}, payload) => {
  try {
    const response = await AkauntingService.getChartAccounts(payload);
    return response.data.data;
  } catch (error) {
    throw error;    
  }
}

const createChartAccount = async ({}, payload) => {
  try {
    const response =  await AkauntingService.createChartAccount(payload);
    return response.data || [];
  } catch(error) {
    throw error;
  }
}

const updateChartAccount = async ({}, payload) => {
  try {
    const response =  await AkauntingService.updateChartAccount(payload);
    return response.data;
  } catch(error) {
    throw error;
  }
}

const deleteChartAccount = async ({getters}, payload) => {
  try {
    return await AkauntingService.deleteChartAccount(payload, {qbo_enabled: getters.isQBOEnabled});
  } catch(error) {
    throw error;
  }
}

const enableChartAccount = async ({getters}, payload) => {
  try {
    return await AkauntingService.enableChartAccount(payload, {qbo_enabled: getters.isQBOEnabled});
  } catch(error) {
    throw error;
  }
}

const disableChartAccount = async ({getters}, payload) => {
  try {
    return await AkauntingService.disableChartAccount(payload, {qbo_enabled: getters.isQBOEnabled});
  } catch(error) {
    throw error;
  }
}


export default{
  createCurrencyForm,
  updateCurrencyForm,
  deleteCurrency,
  getAccountingCurrencies,
  createInvoiceForm, 
  getCustomersData,
  createCustomerForm,
  updateCustomerForm,
  deleteCustomer,
  getQuickbooksItemsByCompany,
  getAccounting,
  getAkauntingAccounts,
  getQBOAccounts,
  createItemForm,
  updateItemForm,
  getItemsData,
  getQBOExpenseAccounts,
  getQBOIncomeAccounts,
  getQBOInventoryAssetAccounts,
  getQBOInventoryExpenseAccounts,
  getQBOInventoryIncomeAccounts,
  disableItem,
  getVendorsData,
  getCurrencies,
  createVendorForm,
  updateVendorForm,
  disableVendor,
  getQBOConnection,
  createAccountingCompany,
  getQuickbooksLoginUrl,
  getAkauntingCategoryExpense,
  createBillingForm,
  getBillsData,
  updateBillForm,
  deleteBill,
  getBill,
  billReceived,
  billCancelled,
  setLastUrlPath,
  signOutQBO,
  getInvoicesData,
  deleteInvoice,
  updateInvoice,
  getAccountsData,
  createAccountForm,
  updateAccountForm,
  deleteAccount,
  getCategoryBasedItemData,
  createCategoryBasedItemForm,
  updateCategoryBasedItemForm,
  getQBOAccountTypes,
  disableAccountBasedItem,
  getQBOParentAccounts,
  getCategories,
  getChartAccountAndTypes,
  getChartAccounts,
  createChartAccount,
  updateChartAccount,
  deleteChartAccount,
  enableChartAccount,
  disableChartAccount,
}