export default {
  itemList:[],
  // vendorLists:[],
  expenseAccountLists: [],
  incomeAccountLists: [],
  QBOExpenseAccountLists: [],
  QBOIncomeAccountLists: [],
  QBOInventoryExpenseAccountLists: [],
  QBOInventoryIncomeAccountLists: [],
  QBOInventoryAssetAccountLists: [],
  isQBOAccountLoading: false,
  // servicesData: [],
  // isServicesDataLoading: true,
  currencies: [],
  // isAccountingConnected: false,
  // isQBOConnected: false,
  companyInformation: null,
  QBOInformation: null,
  isCheckingAccounting: true,
  isCheckingQBO: false,
  // billsData: [],
  // isBillDataLoading: false,
  accountingLastPath: null,
}