/**
 * @typedef {import('./state').fundState} fundState
 */
import state from './state';
import {accountService, receiveService, sendService} from '@/services/solidfi';
/** @type {import('axios').AxiosInstance} */

export default {
  namespaced: true,
  state,
  getters: {
    /**
     * @param {fundState} state
     * @return {fundState['plaidTokenDetails']}
     */
    getPlaidToken(state) {
      return state.plaidTokenDetails;
    },
    /**
     *@param {fundState} state
     * @return {fundState['pullFundDetails']}
     */
    getPullFundDetails(state) {
      return state.pullFundDetails;
    },
    /**
     *@param {fundState} state
     * @return {fundState['pullFundResponseDetails']}
     */
    getPullFundResponseDetails(state) {
      return state.pullFundResponseDetails;
    },
    /**
     *@param {fundState} state
     * @return {fundState['moveFundDetails']}
     */
    getMoveFundDetails(state) {
      return state.moveFundDetails;
    },
    /**
     *@param {fundState} state
     * @return {fundState['moveFundResponseDetails']}
     */
    getMoveFundResponseDetails(state) {
      return state.moveFundResponseDetails;
    },
  },
  mutations: {
    /**
     * @param {fundState} state
     * @param {fundState['plaidTokenDetails']} payload
     */
    setPlaidToken(state, payload) {
      state.plaidTokenDetails = payload;
    },
    /**
     * @param {fundState} state
     * @param {fundState['pullFundDetails']} payload
     */
    setPullFundDetails(state, payload) {
      state.pullFundDetails = payload;
    },
    /**
     * set pull fund response
     * @param {fundState} state
     * @param {fundState['pullFundResponseDetails']} payload
     */
    setPullFundResponseDetails(state, payload) {
      state.pullFundResponseDetails = payload;
    },
    /**
     * @param {fundState} state
     * @param {fundState['moveFundDetails']} payload
     */
    setMoveFundDetails(state, payload) {
      state.moveFundDetails = payload;
    },
    /**
     * set move fund response
     * @param {fundState} state
     * @param {fundState['moveFundResponseDetails']} payload
     */
  },
  actions: {
    /**
     * list all contacts
     * @param {Object} param0
     * @param {Function} param0.commit
     * @param {string} accountId
     * @returns {Promise<contactState['contacts']>}
     */
    fetchPlaidToken({ commit }, accountId) {
      return new Promise((resolve, reject) => {
        accountService.plaidToken(accountId)
          .then(({ data }) => {
            console.log(data);
            commit('setPlaidToken', data);
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * link account
     */
    linkAccount(_, { plaidToken, plaidAccountId, accountId }) {
      return new Promise((resolve, reject) => {
        accountService.plaidAccount(accountId,{
          plaidToken,
          plaidAccountId
        })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * ach receive
     */
    receiveAch({ commit }, payload) {
      return new Promise((resolve, reject) => {
        receiveService.ach(payload)
          .then(({ data }) => {
            commit('setPullFundResponseDetails', data);
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    /**
     * ach send
     */
    sendAch({ commit }, payload) {
      return new Promise((resolve, reject) => {
        sendService.ach(payload)
          .then(({ data }) => {
            commit('setMoveFundResponseDetails', data);
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    }
  }
};
