import SolidService from '@/services/solidfi/solid.service';

export default class SendService extends SolidService {
  constructor() {
    super();
  }

  ach($payload) {
    const url = this.backendSolidBaseUrl + '/send/ach'
    return super.post(url, $payload)
  }

  intrabank($payload) {
    const url = this.backendSolidBaseUrl + '/send/intrabank'
    return super.post(url, $payload)
  }

  wire($payload) {
    const url = this.backendSolidBaseUrl + '/send/wire'
    return super.post(url, $payload)
  }

  card($payload) {
    const url = this.backendSolidBaseUrl + '/send/card'
    return super.post(url, $payload)
  }

  check($payload) {
    const url = this.backendSolidBaseUrl + '/send/check'
    return super.post(url, $payload)
  }
}